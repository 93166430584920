import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, message, Popconfirm } from "antd";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import "./index.css";
import MUIDataTable from "mui-datatables";
import axios from "../../api";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const ListView = ({ assets, isAsset, fetchAssets }) => {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    localStorage.setItem("assetId", id);
    localStorage.setItem("mode", "edit"); // Set mode to view
    navigate("/map");
  };

  const confirmDelete = async (id) => {
    try {
      await axios.delete(`/assests/${id}`);
      message.success("Assest deleted successfully");
      fetchAssets();
    } catch (error) {
      console.error("Error details:", error);
      message.error(error.response.data.error);
    }
  };

  const handleView = (id) => {
    localStorage.setItem("assetId", id);
    localStorage.setItem("mode", "view"); // Set mode to view
    navigate("/map");
  };

  const options = {
    responsive: "standard",
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: true,
    rowsPerPageOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50], // Add 15 to the options
    rowsPerPage: 30,
  };

  const columns = [
    {
      name: "assest_name",
      label: isAsset ? "Site Name" : "License Name",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value, tableMeta) => {
          const assetId = tableMeta.rowData[isAsset ? 16 : 43]; // Assuming asset_id is available in the same row
          return (
            <span
              style={{
                color: "#3f51b5",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleView(assetId);
              }}>
              {value}
            </span>
          );
        },
      },
    },

    {
      name: "address",
      label: isAsset ? "Address" : "Location",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "assest_id",
      label: "Actions",
      options: {
        sort: false,
        display: "true",
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  padding: "6px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minWidth: "auto",
                  margin: "1px",
                }}
                onClick={() => handleEdit(value)}>
                <Tooltip title="Edit">
                  <EditIcon style={{ color: "#3f51b5" }} />
                </Tooltip>
              </Button>
              <Button
                style={{
                  padding: "6px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minWidth: "auto",
                  margin: "1px",
                }}
                onClick={() => handleView(value)}>
                <Tooltip title="View">
                  <VisibilityIcon style={{ color: "#330066" }} />
                </Tooltip>
              </Button>
              <Popconfirm
                title="Delete the asset"
                description="Are you sure to delete this asset?"
                onConfirm={() => confirmDelete(value)}
                okText="Yes"
                cancelText="No">
                <Tooltip title="Delete asset">
                  <Button style={{ marginRight: "2px" }}>
                    <DeleteIcon color="error" />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          );
        },
      },
    },
    {
      name: "latitude",
      label: "Latitude",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "longitude",
      label: "Longitude",
      options: {
        sort: true,
        display: "false",
      },
    },
  ];
  if (isAsset) {
    columns.splice(
      1,
      0, // Add columns after "Doc Number"
      {
        name: "company_profile_name",
        label: "Company Wise",
        options: {
          sort: true,
          display: "true",
        },
      },
      {
        name: "document_number",
        label: "Document No.",
        options: {
          sort: true,
          display: "true",
        },
      },
      {
        name: "start_date",
        label: "Year of Registration",
        options: {
          filter: false,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },
      {
        name: "end_date",
        label: "Expiry date of Lease",
        options: {
          filter: false,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },
      {
        name: "ownership_name",
        label: "Own / Lease Hold",
        options: {
          sort: true,
          display: "true",
        },
      },
      {
        name: "survey_number",
        label: "Sy. No.",
        options: {
          sort: true,
          display: "true",
        },
      },
      {
        name: "size_covered",
        label: "Acres",
        options: {
          sort: true,
          display: "true",
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return value;
          },
        },
      },
      {
        name: "site_category_name",
        label: "Purpose",
        options: {
          sort: true,
          display: "true",
        },
      },
      {
        name: "material_name",
        label: "Name of the Product",
      },
      {
        name: "license_name",
        label: "License Name",
        options: {
          sort: true,
          display: "true",
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return value;
          },
        },
      },
      {
        name: "status",
        label: "License Status",
        options: {
          sort: true,
          display: "true",
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return value;
          },
          setCellProps: () => ({
            style: { minWidth: "120px" }, // Set the width of the column
          }),
        },
      },
      {
        name: "operation_status",
        label: "Operation Status",
        options: {
          sort: true,
          display: "true",
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return value;
          },
        },
      },
      {
        name: "emergency_contact_person",
        label: "Contact Person Name",
        options: {
          sort: true,
          display: "false",
        },
      },
      {
        name: "emergency_contact_phone",
        label: "Contact Person Number",
        options: {
          sort: true,
          display: "false",
        },
      }
    );
  } else {
    columns.splice(
      1,
      0, // Add column after "Doc Number" survey number
      {
        name: "material_name",
        label: "Name of the Product",
      },
      {
        name: "survey_number",
        label: "Survey Number",
      },
      {
        name: "site_size",
        label: "Extant(Ha)",
      },
      {
        name: "status",
        label: "License Status",
      },
      {
        name: "operation_status",
        label: "Operation Status",
      },
      {
        name: "start_date",
        label: "Start Date",
        options: {
          filter: false,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },
      {
        name: "end_date",
        label: "End Date",
        options: {
          filter: false,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },
      {
        name: "site_category_name",
        label: "Category",
        options: {
          sort: true,
          display: "false",
        },
      },

      // Mining Lease
      {
        name: "mining_lease_number",
        label: "Mining Lease Number",
        options: { sort: true, display: true },
      },
      {
        name: "mining_lease_status",
        label: "Mining Lease Status",
        options: { sort: true, display: true },
      },
      {
        name: "mining_lease_validity",
        label: "Mining Lease Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Lease Deed
      {
        name: "lease_deed_number",
        label: "Lease Deed Number",
        options: { sort: true, display: true },
      },
      {
        name: "lease_deed_status",
        label: "Lease Deed Status",
        options: { sort: true, display: true },
      },
      {
        name: "lease_deed_validity",
        label: "Lease Deed Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Mining Plan
      {
        name: "mining_plan_number",
        label: "Mining Plan Number",
        options: { sort: true, display: true },
      },
      {
        name: "mining_plan_status",
        label: "Mining Plan Status",
        options: { sort: true, display: true },
      },
      {
        name: "mining_plan_validity",
        label: "Mining Plan Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Environmental Clearance
      {
        name: "environmental_clearance_number",
        label: "Environmental Clearance Number",
        options: { sort: true, display: true },
      },
      {
        name: "environmental_clearance_status",
        label: "Environmental Clearance Status",
        options: { sort: true, display: true },
      },
      {
        name: "environmental_clearance_validity",
        label: "Environmental Clearance Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Consent to Operate
      {
        name: "consent_operate_number",
        label: "Consent to Operate Number",
        options: { sort: true, display: true },
      },
      {
        name: "consent_operate_status",
        label: "Consent to Operate Status",
        options: { sort: true, display: true },
      },
      {
        name: "consent_operate_validity",
        label: "Consent to Operate Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Consent to Establish
      {
        name: "consent_establish_number",
        label: "Consent to Establish Number",
        options: { sort: true, display: true },
      },
      {
        name: "consent_establish_status",
        label: "Consent to Establish Status",
        options: { sort: true, display: true },
      },
      {
        name: "consent_establish_validity",
        label: "Consent to Establish Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Storage of Diesel
      {
        name: "diesel_storage_number",
        label: "Diesel Storage Number",
        options: { sort: true, display: true },
      },
      {
        name: "diesel_storage_status",
        label: "Diesel Storage Status",
        options: { sort: true, display: true },
      },
      {
        name: "diesel_storage_validity",
        label: "Diesel Storage Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Heavy Earthmoving Machinery
      {
        name: "machinery_license_number",
        label: "Machinery License Number",
        options: { sort: true, display: true },
      },
      {
        name: "machinery_license_status",
        label: "Machinery License Status",
        options: { sort: true, display: true },
      },
      {
        name: "machinery_license_validity",
        label: "Machinery License Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Opening of Mine Notice
      {
        name: "mine_opening_number",
        label: "Mine Opening Notice Number",
        options: { sort: true, display: true },
      },
      {
        name: "mine_opening_status",
        label: "Mine Opening Notice Status",
        options: { sort: true, display: true },
      },
      {
        name: "mine_opening_validity",
        label: "Mine Opening Notice Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Appointment of Mines Manager
      {
        name: "mine_manager_number",
        label: "Mine Manager Number",
        options: { sort: true, display: true },
      },
      {
        name: "mine_manager_status",
        label: "Mine Manager Status",
        options: { sort: true, display: true },
      },
      {
        name: "mine_manager_validity",
        label: "Mine Manager Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      },

      // Annual Return
      {
        name: "annual_return_number",
        label: "Annual Return Number",
        options: { sort: true, display: true },
      },
      {
        name: "annual_return_status",
        label: "Annual Return Status",
        options: { sort: true, display: true },
      },
      {
        name: "annual_return_validity",
        label: "Annual Return Validity",
        options: {
          sort: true,
          display: true,
          customBodyRender: (value) => {
            if (value === null) {
              return "N/A"; // Or any other placeholder for null values
            }
            return convert(value);
          },
        },
      }
    );
  }
  columns.forEach((column) => {
    if (column.label === "Actions") {
      return;
    }
    if (!column.options) {
      column.options = {};
    }
    column.options.setCellProps = () => ({
      style: { minWidth: "120px", maxWidth: "120px" },
    });
  });
  const convert = (dateVal) => {
    let date = new Date(dateVal),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  return (
    <Container fluid>
      <Box
        mt={3}
        sx={{ width: "100%", bgcolor: "background.paper", marginBottom: 10 }}>
        <MUIDataTable
          title={isAsset ? "Assets List" : "License List"}
          data={assets}
          columns={columns}
          options={options}
        />
      </Box>
    </Container>
  );
};
ListView.propTypes = {
  assets: PropTypes.array, // Assuming assets is an array
  isAsset: PropTypes.bool, // Assuming isAsset is a boolean
  fetchAssets: PropTypes.func, // Assuming fetchAssets is a function
};

export default ListView;
