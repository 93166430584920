import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, ListItem, Typography, Link } from "@mui/material";
import axios from "../../api";

const NotificationItem = (props) => {
  const { item, fetchdata } = props;
  const [isRead, setIsRead] = useState(item?.markas_read === 1);
  const handleMarkAsRead = async () => {
    try {
      await axios.put(`/notification/${item.id}`);
      fetchdata(); // <-- Corrected usage with parentheses
      setIsRead(true);
    } catch (error) {
      console.log("Error marking notification as read", error);
    }
  };
  const convert = (dateVal) => {
    let date = new Date(dateVal),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day,mnth,date.getFullYear()].join("/");
  };
  return (
    <ListItem
      sx={{
        padding: "8px 20px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        backgroundColor: isRead ? 'transparent' : 'whitesmoke',
      }}
      className="item-hover">
      <Box
        sx={{
          fontSize: 14,
          color: (theme) =>
            isRead ? theme.palette.text.secondary : theme.palette.text.primary,
        }}>
        <Typography>{item?.notification_message}</Typography>
      </Box>
      <Box
        sx={{
          fontSize: 10,
          color: (theme) =>
            isRead ? theme.palette.text.secondary : theme.palette.text.primary,
          display: "flex",
          justifyContent: "space-between", // Ensures spacing between items
          alignItems: "center", // Aligns items vertically
          width: "100%", // Ensures full width
        }}>
        <Typography>Date:{convert(item?.created_at)}</Typography>        
        {!isRead && (
          <Link
            component="button"
            variant="body2"
            onClick={handleMarkAsRead}
            sx={{
              alignSelf: "flex-end",
              textDecoration: "none",
              cursor: "pointer",
              color: "#2196f3",
            }}>
            Mark as Read
          </Link>
        )}
      </Box>
    </ListItem>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  fetchdata: PropTypes.func,
};
