import React, { useState, useEffect } from "react";
import { Modal, Button, message, Popconfirm } from "antd";
import "./index.css";
import MUIDataTable from "mui-datatables";
import { Edit as EditIcon,  Delete as DeleteIcon } from "@mui/icons-material";
import LockResetIcon from "@mui/icons-material/LockReset";
import DeactivateIcon from "@mui/icons-material/PersonOff";
import ActivateIcon from "@mui/icons-material/Person";
import { Box, Grid, Switch, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const getMuiTheme = (theme) =>
  createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left", // Align cell text to the left
            textTransform: "capitalize", // Capitalize cell text
          },
        },
      },
    },
  });

const validationSchema = Yup.object({
  user_name: Yup.string()
    .matches(/^[A-Za-z0-9]+$/, "Username must only contain letters and numbers") // Allow letters and numbers
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username must be at most 20 characters")
    .required("Username is required"),
  user_email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("User Email is required")
    .test(
      "no-spaces",
      "Email cannot contain spaces",
      (value) => !/\s/.test(value)
    ),
  password: Yup.string()
    .required("Please enter password")
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .test(
      "no-spaces",
      "Password cannot contain spaces",
      (value) => !/\s/.test(value)
    ),
  document_password: Yup.string()
    .required("Please enter password")
    .min(6, "Password is too short - should be 6 chars minimum.")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .test(
      "no-spaces",
      "Password cannot contain spaces",
      (value) => !/\s/.test(value)
    ),
  // user_role: Yup.string().required("User Type is required"),
});

function Admin() {
  const user = useSelector((state) => state.auth.user);

  const [fetchUsers, setFetchUsers] = useState([]);
  const [editItem, setEditItem] = useState({
    user_id: null,
    user_email: "",
    user_name: "",
    user_roles: null,
    user_permission: [],
    is_active: null,
    is_deleted: null,
  });
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_name: "",
      user_email: "",
      password: "",
      document_password: "",
      user_role: 1,
      user_permission: [], // Ensure it's an empty array initially
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveUser(values);
    },
  });

  const fetchUsersData = async () => {
    try {
      const response = await axios.get("/users");
      const { data } = response;
      setFetchUsers(data.list);
    } catch (error) {
      console.error("error", error);
    }
  };

  const confirmDeactivate = async (userId, isActive) => {
    try {
      await axios.put(`/users/status/${userId}`, { is_active: isActive });
      fetchUsersData();
      message.success("User status updated successfully");
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const confirmEdit = (e) => {
    e.preventDefault();
    fetchUsersData();
  };

  const handleCreateUser = () => {
    setShowCreateModal(true);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowCreateModal(false);
  };
  const handleModalEditClose = () => {
    formik.resetForm();
    setShowEditModal(false);
  };

  const handleEditUsernameChange = (event) => {
    setEditItem({ ...editItem, user_name: event.target.value });
  };

  const handleSaveUser = async (values) => {
    const body = {
      user_email: values.user_email,
      user_name: values.user_name,
      password: values.password,
      document_password: values.document_password,
      user_role: values.user_role,
      user_permission: values?.user_permission.join(",") || "",
      is_active: true,
      is_deleted: false,
      email_notify: false,
      created_by: user?.user_id + "", // login user id
      updated_by: user?.user_id + "", // login user id
    };

    try {
      const response = await axios.post("/users/add-user", body);
      if (response.status === 201) {
        message.success("User created successfully");
        setShowCreateModal(false);
        formik.resetForm();
        fetchUsersData();
      }
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  const handleEditUser = async (id) => {
    setShowEditModal(true);
    try {
      await axios.put(`/users/edit-user/${id}`, editItem);
      setShowEditModal(false);
      message.success("User updated successfully");
      fetchUsersData();
    } catch (error) {
      setShowEditModal(true);
      message.error(error.response.data.error);
    }
  };

  const confirmDelete = async (id) => {
    try {
      await axios.put(`/users/delete-user/${id}`);
      message.success("User deleted successfully");
      fetchUsersData();
    } catch (error) {
      console.error("Error details:", error);
      message.error(error.response.data.error);
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await axios.post(`/auth/forgot-password`, {
        email,
      });
      if (response.status === 200) {
        message.success("Sent mail successfully!");
      }
    } catch (error) {
      console.error(error.response.data.error);
      message.error({ submit: error.response.data.error || error.message });
    }
  };

  const handleEdit = async (id) => {
    setShowEditModal(true);

    const editUser = fetchUsers.find((each) => each.user_id === id);

    const updateItem = {
      user_id: editUser.user_id,
      user_email: editUser.user_email,
      user_name: editUser.user_name,
      user_role: editUser.user_role,
      user_permission: editUser.user_permission,
      is_active: editUser.is_active,
      is_deleted: false,
    };
    setEditItem(updateItem);
  };

  const confirmStatus = async (rowData, isActive) => {
    const user_id = rowData.user_id;
    try {
      await axios.put(`/users/status/${user_id}`, {
        is_active: isActive,
      });
      fetchUsersData();
      message.success("Status updated successfully");
    } catch (error) {
      console.error("error", error);
    }
  };
  const options = {
    selectableRows: "none", // "none" will hide checkboxes on rows
    selectableRowsHeader: false, // Set to false to hide the header checkbox
    print: false,
    download: false,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };
  const CustomToolbar = () => {
    return (
      <button
        className="btn btn-success mb-2"
        style={{ background: "#330066" }}
        onClick={handleCreateUser}
      >
        Create User
      </button>
    );
  };
  const columns = [
    {
      name: "user_name",
      label: "User Name",
    },
    {
      name: "user_email",
      label: "User Email",
    },
    {
      name: "role_name",
      label: "User Type",
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <div style={{ textAlign: "center" }}>
              <Switch
                checked={value}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(event) => confirmStatus(data, event.target.checked)}
              />
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];

          return (
            <div className="actions-wrapper">
              <Tooltip title="Edit Users">
                <Button
                  style={{ marginRight: "2px" }}
                  onClick={() => handleEdit(data?.user_id)}
                >
                  <EditIcon color="primary" />
                </Button>
              </Tooltip>
              <Popconfirm
                title={`${
                  !data?.is_active ? "Activate" : "Deactivate"
                } the user`}
                description={`Are you sure to ${
                  data?.is_active ? "deactivate" : "activate"
                } this user?`}
                onConfirm={() =>
                  confirmDeactivate(data.user_id, !data.is_active)
                }
                okText="Yes"
                cancelText="No"
              >
                <Tooltip
                  title={data?.is_active ? "Active User" : "InActive User"}
                >
                  <Button style={{ marginRight: "2px" }}>
                    {data?.is_active ? (
                      <ActivateIcon color="secondary" />
                    ) : (
                      <DeactivateIcon color="warning" />
                    )}
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title="Delete the user"
                description="Are you sure to delete this user?"
                onConfirm={() => confirmDelete(data.user_id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete User">
                  <Button style={{ marginRight: "2px" }}>
                    <DeleteIcon color="error" />
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Tooltip title="Reset Password">
                <Button onClick={() => resetPassword(data.user_email)}>
                  <LockResetIcon Password color="primary" />
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  return (
      <Container fluid className="mt-4 mb-5">
        {showCreateModal && (
          <Modal
            title={"Create User"}
            open={showCreateModal}
            okButtonProps={{
              style: { display: "none" },
            }}
            cancelButtonProps={{
              style: { display: "none" },
            }}
            closable={false}
          >
            <form className=" form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="username"
                    name="user_name"
                    label="Username"
                    value={formik.values.user_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.user_name &&
                      Boolean(formik.errors.user_name)
                    }
                    helperText={
                      formik.touched.user_name && formik.errors.user_name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="useremail"
                    name="user_email"
                    label="User Email"
                    type="email"
                    value={formik.values.user_email}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.user_email &&
                      Boolean(formik.errors.user_email)
                    }
                    helperText={
                      formik.touched.user_email && formik.errors.user_email
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="documentpassword"
                    name="document_password"
                    label="Document Password"
                    type="password"
                    value={formik.values.document_password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.document_password &&
                      Boolean(formik.errors.document_password)
                    }
                    helperText={
                      formik.touched.document_password &&
                      formik.errors.document_password
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    // onClick={handleSaveUser}
                  >
                    Create
                  </button>
                </Box>
              </Grid>
            </form>
          </Modal>
        )}
        {showEditModal && (
          <Modal
            title={"Edit User"}
            open={showEditModal}
            okButtonProps={{
              style: { display: "none" },
            }}
            cancelButtonProps={{
              style: { display: "none" },
            }}
            closable={false}
          >
            <form className="form" onSubmit={confirmEdit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="username"
                    name="user_name"
                    label="User Name"
                    value={editItem.user_name}
                    onChange={handleEditUsernameChange}
                    error={
                      formik.touched.user_name &&
                      Boolean(formik.errors.user_name)
                    }
                    helperText={
                      formik.touched.user_name && formik.errors.user_name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="useremail"
                    name="user_email"
                    label="User Email"
                    type="email"
                    value={editItem.user_email}
                    onChange={(e) =>
                      setEditItem({ ...editItem, user_email: e.target.value })
                    }
                    error={
                      formik.touched.user_email &&
                      Boolean(formik.errors.user_email)
                    }
                    helperText={
                      formik.touched.user_email && formik.errors.user_email
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <button
                    style={{
                      position: "relative",
                      borderColor: "#2196f3",
                      color: "#2196f3",
                    }}
                    type="button"
                    className="btn"
                    onClick={handleModalEditClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    style={{
                      position: "relative",
                      backgroundColor: "#2196f3",
                      marginLeft: 2.5,
                    }}
                    onClick={() => handleEditUser(editItem.user_id)}
                  >
                    Save
                  </button>
                </Box>
              </Grid>
            </form>
          </Modal>
        )}
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            className="m-super-admin"
            title={"Manage Users"}
            data={fetchUsers}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Container>
  );
}
export default Admin;
