import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import "./index.css";
import { CgProfile } from "react-icons/cg";
import axios from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../store/reducers/auth";
import { message } from "antd";
import { Badge, IconButton, Drawer } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppNotificationContent from "../AppNotifications/AppNotificationContent";

const Navbar = () => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const location = useLocation();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`/notification`, {
        params: { user_id: user.user_id },
      });
      const { data } = response;
      setNotificationData(data.list);
      const UnreadCount = data.list.filter(
        (emailObj) => emailObj.markas_read === 0
      );
      setNotifications(UnreadCount.length);
    } catch (error) {
      console.log("Error fetching notifications", error);
    }
  };
  const markAllRead = async (itemId) => {
    try {
      await axios.put(`/notification/user/${itemId}`);
      fetchNotifications();
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await axios.post(`/auth/forgot-password`, {
        email,
      });
      if (response.status === 200) {
        message.success("Email is sent successfully!");
      }
    } catch (error) {
      console.error(error.response.data.error);
      message.error({ submit: error.response.data.error || error.message });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  let showAdmin = false;

  if (user?.user_role === 1) {
    showAdmin = true;
  }

  const toggleProfileMenu = (event) => {
    event.stopPropagation();
    setProfileMenuOpen(!profileMenuOpen);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setProfileMenuOpen(false);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setProfileMenuOpen(false); // Close the profile menu when the location changes
  }, [location]);

  useEffect(() => {
    fetchNotifications(); // Close the profile menu when the location changes
  }, []);

  const handleLogout = async () => {
    await axios.get("/auth/logout");
    localStorage.removeItem("token");
    dispatch(userLogout({ user: null }));
    navigate("/");
    window.location.reload();
  };
  return (
    <nav className="navbar" onClick={() => setProfileMenuOpen(false)}>
      <div className="navbar-logo">
        <Link to="/map">
          <img src={`${process.env.PUBLIC_URL}/company_logo.png`} alt="logo" />
        </Link>
      </div>
      <div className={`navbar-buttons ${isOpen ? "open" : ""}`}>
        <NavLink
          key="dashboard"
          to="/dashboard"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}>
          Dashboard
        </NavLink>
        <NavLink
          key="map"
          to="/map"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}>
          Map View
        </NavLink>
        <NavLink
          key="ListView"
          to="/ListView"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}>
          Asset List
        </NavLink>
        <NavLink
          key="LicenseListView"
          to="/License/ListView"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}>
          License List
        </NavLink>
        {showAdmin && (
          <>
            <NavLink
              key="admin"
              to="/admin"
              className={({ isActive }) =>
                `nav-button ${isActive ? "active" : ""}`
              }
              onClick={() => setIsOpen(false)}>
              User Management
            </NavLink>
            <NavLink
              key="Settings"
              to="/Settings"
              className={({ isActive }) =>
                `nav-button ${isActive ? "active" : ""}`
              }
              onClick={() => setIsOpen(false)}>
              Settings
            </NavLink>

            <NavLink
              key="Reports"
              to="/license/reports"
              className={({ isActive }) =>
                `nav-button ${isActive ? "active" : ""}`
              }
              onClick={() => setIsOpen(false)}>
              License Report
            </NavLink>
          </>
        )}
        <NavLink
          key="doumentlist"
          to="/documentlist"
          className={({ isActive }) => `nav-button ${isActive ? "active" : ""}`}
          onClick={() => setIsOpen(false)}>
          Documents Expiry Report
        </NavLink>
        <div className="profile-menu" onClick={(e) => e.stopPropagation()}>
          <button
            className="profile-icon text-white"
            onClick={toggleProfileMenu}>
            <CgProfile className="icon" />
          </button>
          {profileMenuOpen && (
            <div className="profile-submenu">
              <NavLink
                // to="/changepassword"
                onClick={() => resetPassword(user?.user_email)}>
                Change Password
              </NavLink>
              <NavLink
                to="/"
                onClick={() => {
                  setProfileMenuOpen(false);
                  handleLogout();
                }}>
                Logout
              </NavLink>
            </div>
          )}
        </div>
        <IconButton
          onClick={() => setIsNotificationOpen(true)}
          className="profile-menu"
          color="inherit">
          <Badge badgeContent={notifications} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </div>

      {/* Notification Drawer (Sidebar) */}
      <Drawer
        anchor="right"
        open={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}>
        <AppNotificationContent
          sxStyle={{ width: 300 }}
          onClose={() => setIsNotificationOpen(false)}
          notificationData={notificationData}
          fetchdata={fetchNotifications}
          markAllRead={markAllRead}
          user={user}
        />
      </Drawer>
      <button className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </button>
    </nav>
  );
};

export default Navbar;
