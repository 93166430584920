import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tabs/style/react-tabs.css";
import axios from "../../api";
import ListView from "./index";

const AssetListView = () => {
  const [assets, setAssets] = useState([]);
  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    try {
      const response = await axios.get("/assests/asset-list", {
        params: {
          filters: {
            type: "A",
          },
        },
      });
      const { data } = response;
      setAssets(data.list);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return <ListView assets={assets} isAsset={true} fetchAssets={fetchAssets} />;
};

export default AssetListView;
