import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Avatar, Box, Card, Typography } from "@mui/material";

const InfoCard = ({
  icon: Icon,
  backgroundColor,
  iconColor,
  value,
  description,
}) => {
  return (
    <Card
      className="card-hover"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: 0,
        backgroundColor: "#fff",
        borderRadius: "8px",
        overflow: "hidden",
        transition: "0.3s ease",
        marginRight: "10px",
      }}>
      <Box
        className="icon-section"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          padding: "8px",
          minWidth: "70px",
          transition: "background-color 0.3s ease",
        }}>
        {Icon && (
          <Avatar
            className="circle-background"
            sx={{
              backgroundColor,
              width: 50,
              height: 50,
              transition: "background-color 0.3s ease",
            }}>
            <Icon
              className="icon"
              sx={{
                color: iconColor,
                fontSize: 32,
                transition: "color 0.3s ease",
              }}
            />
          </Avatar>
        )}
      </Box>
      <Box sx={{ width: "calc(100% - 70px)", marginLeft: 2 }}>
        <Typography
          component="h5"
          variant="inherit"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            fontSize: 16,
            color: "inherit",
            fontWeight: "600",
            marginTop: "1rem",
          }}>
          {value}
        </Typography>
        <Box
          component="p"
          sx={{
            pt: 0.5,
            color: "text.secondary",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}>
          {description}
        </Box>
      </Box>
    </Card>
  );
};

// Add PropTypes validation
InfoCard.propTypes = {
  icon: PropTypes.elementType.isRequired, // Validate the 'icon' as a React component type
  backgroundColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
};

export default InfoCard;
