import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box, Typography } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import "../ListView/index.css";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

const ListView = ({
  siteCategorySorting,
  setSiteCategorySorting,
  mineMaterialSorting,
  setMineMaterialSorting,
  companyProfileSorting,
  setCompanyProfileSorting,
  ownershipSorting,
  setOwnershipSorting,
  countrySorting,
  setCountrySorting,
  stateSorting,
  setStateSorting,
  districtSorting,
  setDistrictSorting,
  licenseStartDateSorting,
  setLicenseStartDateSorting,
  licenseEndDateSorting,
  setLicenseEndDateSorting,
  licenseStatusSorting,
  setLicenseStatusSorting,
  licenseSiteCategorySorting,
  setLicenseSiteCategorySorting,
  licenseMineMaterialSorting,
  setLicenseMineMaterialSorting,
  licenseCountrySorting,
  setLicenseCountrySorting,
  licenseStateSorting,
  setLicenseStateSorting,
  licenseDistrictSorting,
  setLicenseDistrictSorting,
  setLicenseAssestNameSorting,
  licenseAssestNameSorting,
  setLicenseOperationStatusSorting,
  licenseOperationStatusSorting,
  setLicenseAddressSorting,
  licenseAddressSorting,
  setLicenseSiteSizeSorting,
  licenseSiteSizeSorting,
  setLicenseVillageSorting,
  licenseVillageSorting,
  setLicenseSurveyNumberSorting,
  licenseSurveyNumberSorting,
  setLicensePinSorting,
  licensePinSorting,
  setAssestNameSorting,
  assestNameSorting,
  setAddressSorting,
  addressSorting,
  assets,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  totalCount,
  onSearchOrder,
  totalSiteSize,
}) => {
  const convert = (dateVal) => {
    let date = new Date(dateVal),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  
  const columns = [
    {
      name: "license_assest_name",
      label: "License Name",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_start_date",
      label: "License Start Date",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value) => {
          if (value === null) {
            return "N/A"; // Or any other placeholder for null values
          }
          return convert(value);
        },
      },
    },
    {
      name: "license_end_date",
      label: "License End Date",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value) => {
          if (value === null) {
            return "N/A"; // Or any other placeholder for null values
          }
          return convert(value);
        },
      },
    },
    {
      name: "license_status",
      label: "Status",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_operation_status",
      label: "Operation Status",
      options: {
        sort: true,
        display: "true",
      },
    },

    {
      name: "license_site_categorie",
      label: "License Site Category",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_mine_material",
      label: "License Mine Material",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_country",
      label: "License Country",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_state",
      label: "License State",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_district",
      label: "License District",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_address",
      label: "License Address",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_site_size",
      label: "License Site Size",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_village",
      label: "License City/Village",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_pin",
      label: "License PinCode",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "license_survey_number",
      label: "License Survey Number",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "assest_name",
      label: "Asset Name",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "site_size",
      label: "Asset Site Size",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "address",
      label: "Location",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "material_name",
      label: "Mine Material",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "site_category_name",
      label: "Site Category",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "company_profile_name",
      label: "Company Profile",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "country_name",
      label: "Country",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "state_name",
      label: "State",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "district_name",
      label: "District",
      options: {
        sort: true,
        display: "true",
      },
    },
    // New columns
  ];

  const handleSort = (column) => {
    setSiteCategorySorting(
      column === "site_category_name" ? !siteCategorySorting : null
    );
    setMineMaterialSorting(
      column === "material_name" ? !mineMaterialSorting : null
    );
    setCompanyProfileSorting(
      column === "company_profile_name" ? !companyProfileSorting : null
    );
    setOwnershipSorting(column === "ownership_name" ? !ownershipSorting : null);
    setCountrySorting(column === "country_name" ? !countrySorting : null);
    setStateSorting(column === "state_name" ? !stateSorting : null);
    setDistrictSorting(column === "district_name" ? !districtSorting : null);
    setLicenseStartDateSorting(
      column === "license_start_date" ? !licenseStartDateSorting : null
    );
    setLicenseEndDateSorting(
      column === "license_end_date" ? !licenseEndDateSorting : null
    );
    setLicenseStatusSorting(
      column === "license_status" ? !licenseStatusSorting : null
    );
    // Add sorting logic for new columns
    setLicenseSiteCategorySorting(
      column === "license_site_categorie" ? !licenseSiteCategorySorting : null
    );
    setLicenseMineMaterialSorting(
      column === "license_mine_material" ? !licenseMineMaterialSorting : null
    );
    setLicenseCountrySorting(
      column === "license_country" ? !licenseCountrySorting : null
    );
    setLicenseStateSorting(
      column === "license_state" ? !licenseStateSorting : null
    );
    setLicenseDistrictSorting(
      column === "license_district" ? !licenseDistrictSorting : null
    );
    setLicenseAssestNameSorting(
      column === "license_assest_name" ? !licenseAssestNameSorting : null
    );
    setLicenseOperationStatusSorting(
      column === "license_operation_status"
        ? !licenseOperationStatusSorting
        : null
    );
    setLicenseAddressSorting(
      column === "license_address" ? !licenseAddressSorting : null
    );
    setLicenseSiteSizeSorting(
      column === "license_site_size" ? !licenseSiteSizeSorting : null
    );
    setLicenseVillageSorting(
      column === "license_village" ? !licenseVillageSorting : null
    );
    setLicenseSurveyNumberSorting(
      column === "license_survey_number" ? !licenseSurveyNumberSorting : null
    );
    setLicensePinSorting(column === "license_pin" ? !licensePinSorting : null);
    setAssestNameSorting(column === "assest_name" ? !assestNameSorting : null);
    setAddressSorting(column === "address" ? !addressSorting : null);
  };

  const options = {
    responsive: "standard",
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: false,
    filter: false,
    serverSide: true,
    rowsPerPageOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50, 100], // Add 15 to the options
    rowsPerPage: rowsPerPage,
    count: totalCount, // Set the total count for pagination
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage); // Update the rowsPerPage state
      setPage(0); // Reset the page to 0 on rowsPerPage change
    },
    onChangePage: (newPage) => {
      setPage(newPage); // Update the current page
    },
    onColumnSortChange: handleSort, // Handle sorting if needed
    onSearchChange: onSearchOrder,
  };

  return (
    <Container fluid>
      <Typography gutterBottom sx={{ textAlign: "right", marginRight: "20px" }}>
        Total Site Size: <b>{totalSiteSize}</b>
      </Typography>
      <Box
        mt={3}
        sx={{ width: "100%", bgcolor: "background.paper", marginBottom: 10 }}>
        <MUIDataTable
          title={"License Report"}
          data={assets}
          columns={columns}
          options={options}
        />
      </Box>
    </Container>
  );
};

export default ListView;
ListView.propTypes = {
  siteCategorySorting: PropTypes.bool,
  setSiteCategorySorting: PropTypes.func,
  mineMaterialSorting: PropTypes.bool,
  setMineMaterialSorting: PropTypes.func,
  companyProfileSorting: PropTypes.bool,
  setCompanyProfileSorting: PropTypes.func,
  ownershipSorting: PropTypes.bool,
  setOwnershipSorting: PropTypes.func,
  countrySorting: PropTypes.bool,
  setCountrySorting: PropTypes.func,
  stateSorting: PropTypes.bool,
  setStateSorting: PropTypes.func,
  districtSorting: PropTypes.bool,
  setDistrictSorting: PropTypes.func,
  licenseStartDateSorting: PropTypes.bool,
  setLicenseStartDateSorting: PropTypes.func,
  licenseEndDateSorting: PropTypes.bool,
  setLicenseEndDateSorting: PropTypes.func,
  licenseStatusSorting: PropTypes.bool,
  setLicenseStatusSorting: PropTypes.func,
  licenseSiteCategorySorting: PropTypes.bool,
  setLicenseSiteCategorySorting: PropTypes.func,
  licenseMineMaterialSorting: PropTypes.bool,
  setLicenseMineMaterialSorting: PropTypes.func,
  licenseCountrySorting: PropTypes.bool,
  setLicenseCountrySorting: PropTypes.func,
  licenseStateSorting: PropTypes.bool,
  setLicenseStateSorting: PropTypes.func,
  licenseDistrictSorting: PropTypes.bool,
  setLicenseDistrictSorting: PropTypes.func,
  assets: PropTypes.array,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  setPage: PropTypes.func,
  totalCount: PropTypes.number,
  onSearchOrder: PropTypes.func,
  setLicenseAssestNameSorting: PropTypes.func,
  licenseAssestNameSorting: PropTypes.bool,
  setLicenseOperationStatusSorting: PropTypes.func,
  licenseOperationStatusSorting: PropTypes.bool,
  setLicenseAddressSorting: PropTypes.func,
  licenseAddressSorting: PropTypes.bool,
  setLicenseSiteSizeSorting: PropTypes.func,
  licenseSiteSizeSorting: PropTypes.bool,
  setLicenseVillageSorting: PropTypes.func,
  licenseVillageSorting: PropTypes.bool,
  setLicenseSurveyNumberSorting: PropTypes.func,
  licenseSurveyNumberSorting: PropTypes.bool,
  setLicensePinSorting: PropTypes.func,
  licensePinSorting: PropTypes.bool,
  setAssestNameSorting: PropTypes.func,
  assestNameSorting: PropTypes.bool,
  setAddressSorting: PropTypes.func,
  addressSorting: PropTypes.bool,
  totalSiteSize: PropTypes.number,
};
