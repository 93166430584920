import React, { useEffect, useState } from "react";
import { AppsContent } from "../AppsContent";
import axios from "../../api";
import ReportTable from "./ReportTable";
import ReportFilters from "./ReportFilters";
import AppLoader from "../AppLoader";

const LicenseReport = () => {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [productFilter, setProductFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [siteCategorySorting, setSiteCategorySorting] = useState(null);
  const [mineMaterialSorting, setMineMaterialSorting] = useState(null);
  const [companyProfileSorting, setCompanyProfileSorting] = useState(null);
  const [ownershipSorting, setOwnershipSorting] = useState(null);
  const [countrySorting, setCountrySorting] = useState(null);
  const [stateSorting, setStateSorting] = useState(null);
  const [districtSorting, setDistrictSorting] = useState(null);
  const [licenseStartDateSorting, setLicenseStartDateSorting] = useState(null);
  const [licenseEndDateSorting, setLicenseEndDateSorting] = useState(null);
  const [licenseStatusSorting, setLicenseStatusSorting] = useState(null);
  const [licenseSiteCategorySorting, setLicenseSiteCategorySorting] =
    useState(null);
  const [licenseMineMaterialSorting, setLicenseMineMaterialSorting] =
    useState(null);
  const [licenseCountrySorting, setLicenseCountrySorting] = useState(null);
  const [licenseStateSorting, setLicenseStateSorting] = useState(null);
  const [licenseDistrictSorting, setLicenseDistrictSorting] = useState(null);
  const [licenseAssestNameSorting, setLicenseAssestNameSorting] =
    useState(false);
  const [licenseOperationStatusSorting, setLicenseOperationStatusSorting] =
    useState(false);
  const [licenseAddressSorting, setLicenseAddressSorting] = useState(false);
  const [licenseSiteSizeSorting, setLicenseSiteSizeSorting] = useState(false);
  const [licenseVillageSorting, setLicenseVillageSorting] = useState(false);
  const [licenseSurveyNumberSorting, setLicenseSurveyNumberSorting] =
    useState(false);
  const [licensePinSorting, setLicensePinSorting] = useState(false);
  const [assestNameSorting, setAssestNameSorting] = useState(false);
  const [addressSorting, setAddressSorting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSiteCategory, setSelectedSiteCategory] = useState(null);
  const [selectedMineMaterial, setSelectedMineMaterial] = useState(null);
  const [selectedOwnership, setSelectedOwnership] = useState(null);
  const [selectedOperationStatus, setSelectedOperationStatus] = useState(null);
  const [isExpandFilter, setIsExpandFilter] = useState(false);
  const [totalSiteSize,setTotalSiteSize] = useState(0);

  useEffect(() => {
    fetchData();
  }, [
    search,
    page,
    perPage,
    productFilter,
    startDateFilter,
    endDateFilter,
    statusFilter,
    typeFilter,
    siteCategorySorting,
    mineMaterialSorting,
    companyProfileSorting,
    ownershipSorting,
    countrySorting,
    stateSorting,
    stateSorting,
    districtSorting,
    licenseStartDateSorting,
    licenseEndDateSorting,
    licenseStatusSorting,
    licenseSiteCategorySorting,
    licenseMineMaterialSorting,
    licenseCountrySorting,
    licenseStateSorting,
    licenseDistrictSorting,
    licenseAssestNameSorting,
    licenseOperationStatusSorting,
    licenseAddressSorting,
    licenseSiteSizeSorting,
    licenseVillageSorting,
    licenseSurveyNumberSorting,
    licensePinSorting,
    assestNameSorting,
    addressSorting,
    selectedCountry,
    selectedState,
    selectedDistrict,
    selectedCompany,
    selectedSiteCategory,
    selectedMineMaterial,
    selectedOwnership,
    selectedOperationStatus,
  ]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`/assests/license/report`, {
        params: {
          search,
          page,
          perPage,
          filters: {
            product_id: productFilter,
            license_start_date: startDateFilter,
            license_end_date: endDateFilter,
            is_active: statusFilter,
            discount_type: typeFilter,
            country_name: selectedCountry,
            state_name: selectedState,
            district_name: selectedDistrict,
            company_profile_name: selectedCompany,
            site_category_name: selectedSiteCategory,
            material_name: selectedMineMaterial,
            ownership_name: selectedOwnership,
            license_operation_status :selectedOperationStatus,
          },
          sortings: {
            site_category_name: siteCategorySorting,
            material_name: mineMaterialSorting,
            company_profile_name: companyProfileSorting,
            ownership_name: ownershipSorting,
            country_name: countrySorting,
            state_name: stateSorting,
            district_name: districtSorting,
            license_start_date: licenseStartDateSorting,
            license_end_date: licenseEndDateSorting,
            license_status: licenseStatusSorting,
            license_site_categorie: licenseSiteCategorySorting,
            license_mine_material: licenseMineMaterialSorting,
            license_country: licenseCountrySorting,
            license_state: licenseStateSorting,
            license_district: licenseDistrictSorting,
            license_assest_name: licenseAssestNameSorting,
            license_operation_status: licenseOperationStatusSorting,
            license_address: licenseAddressSorting,
            license_site_size: licenseSiteSizeSorting,
            license_village: licenseVillageSorting,
            license_survey_number: licenseSurveyNumberSorting,
            license_pin: licensePinSorting,
            assest_name: assestNameSorting,
            address: addressSorting,
          },
        },
      });

      const { data } = response;
      setList(data.list || []);
      setTotal(data?.total || 0);
      setTotalSiteSize(data?.totalSiteSize || 0)
      data.list.length === 0 && page > 0 && setPage(page - 1);
      setLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  const onSearchOrder = (value) => {
    setSearch(value);
    setPage(0);
  };
  return (
    <>
        <ReportFilters
          setProductFilter={setProductFilter}
          productFilter={productFilter}
          setStartDateFilter={setStartDateFilter}
          startDateFilter={startDateFilter}
          setEndDateFilter={setEndDateFilter}
          endDateFilter={endDateFilter}
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
          setTypeFilter={setTypeFilter}
          typeFilter={typeFilter}
          setPage={setPage}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          selectedDistrict={selectedDistrict}
          setSelectedDistrict={setSelectedDistrict}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedSiteCategory={selectedSiteCategory}
          setSelectedSiteCategory={setSelectedSiteCategory}
          selectedMineMaterial={selectedMineMaterial}
          setSelectedMineMaterial={setSelectedMineMaterial}
          selectedOwnership={selectedOwnership}
          setSelectedOwnership={setSelectedOwnership}
          selectedOperationStatus={selectedOperationStatus}
          setSelectedOperationStatus={setSelectedOperationStatus}
          isExpandFilter={isExpandFilter}
          setIsExpandFilter={setIsExpandFilter}
        />
      <br></br>
      <div className="event-search ps-h">
        {loading && <AppLoader />} {/* Conditionally render loader */}
        <AppsContent
          className="evnt-s-table ps-events"
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}>
          <ReportTable
            siteCategorySorting={siteCategorySorting}
            setSiteCategorySorting={setSiteCategorySorting}
            mineMaterialSorting={mineMaterialSorting}
            setMineMaterialSorting={setMineMaterialSorting}
            companyProfileSorting={companyProfileSorting}
            setCompanyProfileSorting={setCompanyProfileSorting}
            ownershipSorting={ownershipSorting}
            setOwnershipSorting={setOwnershipSorting}
            countrySorting={countrySorting}
            setCountrySorting={setCountrySorting}
            stateSorting={stateSorting}
            setStateSorting={setStateSorting}
            districtSorting={districtSorting}
            setDistrictSorting={setDistrictSorting}
            licenseStartDateSorting={licenseStartDateSorting}
            setLicenseStartDateSorting={setLicenseStartDateSorting}
            licenseEndDateSorting={licenseEndDateSorting}
            setLicenseEndDateSorting={setLicenseEndDateSorting}
            licenseStatusSorting={licenseStatusSorting}
            setLicenseStatusSorting={setLicenseStatusSorting}
            licenseSiteCategorySorting={licenseSiteCategorySorting}
            setLicenseSiteCategorySorting={setLicenseSiteCategorySorting}
            licenseMineMaterialSorting={licenseMineMaterialSorting}
            setLicenseMineMaterialSorting={setLicenseMineMaterialSorting}
            licenseCountrySorting={licenseCountrySorting}
            setLicenseCountrySorting={setLicenseCountrySorting}
            licenseStateSorting={licenseStateSorting}
            setLicenseStateSorting={setLicenseStateSorting}
            licenseDistrictSorting={licenseDistrictSorting}
            setLicenseDistrictSorting={setLicenseDistrictSorting}
            setLicenseAssestNameSorting={setLicenseAssestNameSorting}
            licenseAssestNameSorting={licenseAssestNameSorting}
            setLicenseOperationStatusSorting={setLicenseOperationStatusSorting}
            licenseOperationStatusSorting={licenseOperationStatusSorting}
            setLicenseAddressSorting={setLicenseAddressSorting}
            licenseAddressSorting={licenseAddressSorting}
            setLicenseSiteSizeSorting={setLicenseSiteSizeSorting}
            licenseSiteSizeSorting={licenseSiteSizeSorting}
            setLicenseVillageSorting={setLicenseVillageSorting}
            licenseVillageSorting={licenseVillageSorting}
            setLicenseSurveyNumberSorting={setLicenseSurveyNumberSorting}
            licenseSurveyNumberSorting={licenseSurveyNumberSorting}
            setLicensePinSorting={setLicensePinSorting}
            licensePinSorting={licensePinSorting}
            setAssestNameSorting={setAssestNameSorting}
            assestNameSorting={assestNameSorting}
            setAddressSorting={setAddressSorting}
            addressSorting={addressSorting}
            assets={list}
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            setPage={setPage}
            totalCount={total}
            onSearchOrder={onSearchOrder}
            totalSiteSize={totalSiteSize}
          />
        </AppsContent>
      </div>
    </>
  );
};

export default LicenseReport;
