import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import SimpleBarReact from "simplebar-react";
import { Grid, } from "@mui/material";

const AppGridContainer = ({ children, ...others }) => {
  const isMDDown = false;

  return (
    <Grid container spacing={isMDDown ? 5 : 8} {...others}>
      {children}
    </Grid>
  );
};

AppGridContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppsContentContainer = styled(SimpleBarReact)(() => {
  return {
    width: "100%",
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "column",
  };
});

const AppsContent = ({ isDetailView, fullView, children, ...rest }) => {
  return (
    <AppsContentContainer
      {...rest}
      sx={{
        height: {
          xs: `calc(100% - ${isDetailView ? 60 : 129}px)`,
          sm: `calc(100% - ${fullView ? 0 : 60}px)`,
        },
      }}>
      {children}
    </AppsContentContainer>
  );
};

AppsContent.propTypes = {
  children: PropTypes.node,
  fullView: PropTypes.bool,
  isDetailView: PropTypes.bool,
};

AppsContent.defaultProps = { isDetailView: false };

// Export both components
export { AppGridContainer, AppsContent };
