import React from "react";
import "./index.css";

const Footer = () => {
const currentYear = new Date().getFullYear();
	return (
		<footer className="footer">
			<div className="footer-copy">
				&copy; Midwest Group {currentYear}, All Rights Reserved.
			</div>
		</footer>
	);
};

export default Footer;
