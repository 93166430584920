/* eslint-disable */
import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { message } from "antd";
import axios from "../../api";
// Validation schema
const validationSchema = Yup.object({
  password: Yup.string().required("Please enter password"),
});

const FileAccess = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const [fileData, setFileData] = React.useState(null);
  const [fileType, setFileType] = React.useState("");
  const [error, setError] = React.useState("");
  
  const handleFileOpen = (url, type) => {
    setFileData(null)
    if (type.includes("pdf") || type.includes("image")) {
      // For PDFs and images, open in a new tab
      window.open(url, "_blank");
    } else {
      // For other file types, trigger a download
      const link = document.createElement("a");
      link.href = url;
      link.download = "file"; // You might want to set a default filename or get it from the headers
      link.click();
    }
  };

  return (
      <div className="login">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Card className="signin-form signin-admin-form">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  mb: { xs: 2 },
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Access File
              </Box>
              <Box sx={{ mb: 5, fontSize: 10 }}>
                <Typography component="p">Enter your password</Typography>
              </Box>
              <Formik
                validateOnChange={true}
                initialValues={{
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (
                  data,
                  { setErrors, setSubmitting, resetForm }
                ) => {
                  try {
                    // Send a request to get the file
                    const response = await axios.post(
                      `/file/view-file`,
                      {
                        password: data.password,
                        token: token,
                      },
                      {
                        responseType: "blob", // Important: set the response type to blob
                      }
                    );

                    if (response.status === 200) {
                      const blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                      });
                      const url = URL.createObjectURL(blob);
                      const contentType = response.headers["content-type"];

                      setFileType(contentType);

                      setError("");
                      setSubmitting(false);

                      // Handle file display or download based on the content type
                      handleFileOpen(url, contentType);
                      resetForm();
                      message.success("File fetched successfully!");
                    } else {
                      setError(
                        "Failed to fetch the file. Please check your password."
                      );
                    }
                  } catch (error) {
                    if (error?.response?.status === 401) {
                      message.error("Invalid Password");
                    }
                    setError(error.response?.data?.error || error.message);
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  isSubmitting,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          label="Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>

                    {errors.submit && (
                      <Grid
                        item
                        mt={5}
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormHelperText sx={{ fontSize: "17px" }} error>
                          {errors.submit}
                        </FormHelperText>
                      </Grid>
                    )}

                    <Box mt={4} mb={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          height: 44,
                        }}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Access File
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>

              {fileData && (
                <Box mt={3}>
                  {fileType.includes("pdf") ? (
                    <iframe
                      src={fileData}
                      width="100%"
                      height="600px"
                      title="PDF Viewer"
                    />
                  ) : fileType.includes("image") ? (
                    <img
                      src={fileData}
                      alt="File Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  ) : (
                    <a href={fileData} download>
                      Download the file
                    </a>
                  )}
                </Box>
              )}

              {error && (
                <Typography color="error" mt={2}>
                  {error.response?.data?.error}
                </Typography>
              )}
            </Grid>
          </Card>
        </div>
      </div>
  );
};

export default FileAccess;
