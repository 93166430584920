import React, { useState, useEffect } from "react";
import axios from "../../api";
import AppCard from "../AppCard";
import {
  alpha,
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { AppGridContainer } from "../AppsContent";
import PropTypes from "prop-types";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import NotificatioPeriod from "../../constants/NotificationsPeriod.json";

const ReportFilters = ({
  setStartDateFilter,
  startDateFilter,
  setEndDateFilter,
  endDateFilter,
  setStatusFilter,
  statusFilter,
  setPage,
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
  selectedDistrict,
  setSelectedDistrict,
  selectedCompany,
  setSelectedCompany,
  selectedSiteCategory,
  setSelectedSiteCategory,
  selectedMineMaterial,
  setSelectedMineMaterial,
  selectedOwnership,
  setSelectedOwnership,
  selectedOperationStatus,
  setSelectedOperationStatus,
  isExpandFilter,
  setIsExpandFilter,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [companyProfiles, setCompanyProfiles] = useState([]);
  const [siteCategories, setSiteCategories] = useState([]);
  const [mineMaterials, setMineMaterials] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const operationStatus = NotificatioPeriod.operationStatus;

  const clearFilter = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setStatusFilter(null);
    setSelectedCountry(null);
    setSelectedDistrict(null);
    setSelectedState(null);
    setSelectedCompany(null);
    setSelectedSiteCategory(null);
    setSelectedOperationStatus(null);
    setSelectedOwnership(null);
    setSelectedMineMaterial(null);
    setPage(0);
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === "status") {
      setStatusFilter(value);
      setPage(0);
    }

    if (filterType === "startDate") {
      if (value === "") {
        setStartDateFilter(null);
      } else {
        setStartDateFilter(value);
      }
      setPage(0);
    }
    if (filterType === "endDate") {
      if (value === "") {
        setEndDateFilter(null);
      } else {
        setEndDateFilter(value);
      }
      setPage(0);
    }
  };
  useEffect(() => {
    fetchCommonData();
    fetchCountriesAndStates();
  }, []); // Fetch common data and countries/states once

  const fetchCommonData = async () => {
    try {
      const siteCategoriesResponse = await axios.get("/site-categories");
      setSiteCategories(siteCategoriesResponse.data);

      const mineMaterialResponse = await axios.get("/mine-material");
      setMineMaterials(mineMaterialResponse.data);

      const companyProfileResponse = await axios.get("/company-profile");
      setCompanyProfiles(companyProfileResponse.data);

      const ownershipResponse = await axios.get("/owner-ship");
      setOwnerships(ownershipResponse.data);
    } catch (error) {
      console.error("Error fetching common data", error);
    }
  };
  useEffect(() => {
    fetchStates();
  }, [selectedCountry]);

  const fetchStates = async () => {
    try {
      if (selectedCountry) {
        const statesResponse = await axios.get(
          `/states/country/${selectedCountry}`
        );
        setStates(statesResponse.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  //Fetch district
  useEffect(() => {
    fetchDistrict();
  }, [selectedState]);

  const fetchDistrict = async () => {
    try {
      if (selectedState) {
        const districtsResponse = await axios.get(
          `/districts/state/${selectedState}`
        );
        setDistricts(districtsResponse.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const fetchCountriesAndStates = async () => {
    try {
      const response = await axios.get(`/countries`);
      const { data } = response;
      setCountries(data);

      fetchStates();

      fetchDistrict();
    } catch (error) {
      console.error("Error fetching countries/states", error);
    }
  };
  const toggleFilter = () => {
    setIsExpandFilter(!isExpandFilter);
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  let startDate = new Date(`${startDateFilter?.substring(0, 10)}T12:01:00`);
  let endDate = new Date(`${endDateFilter?.substring(0, 10)}T12:01:00`);
  return (
    <AppCard
      title="License Report Filter"
      style={{ position: "relative" }}
      onClick={toggleFilter}
      action={
        <IconButton
          onClick={toggleFilter}
          size="small"
          style={{
            position: "absolute",
            top: "0",
            right: "0",
          }}>
          {isExpandFilter ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </IconButton>
      }>
      {isExpandFilter ? (
        <AppGridContainer spacing={5} onClick={stopPropagation}>
          <Grid item xs={12} md={3} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={1}>
                <DatePicker
                  label="Start Date"
                  sx={{
                    width: "100%",
                    backgroundColor: "background.paper",
                    color: "text.primary",
                  }}
                  value={startDateFilter ? startDate : null}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => {
                    const formattedDate = dayjs(value)
                      .startOf("day")
                      .format("YYYY-MM-DD");
                    handleFilterChange(
                      "startDate",
                      formattedDate === "Invalid Date" ? null : formattedDate
                    );
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={1}>
                <DatePicker
                  label="End Date"
                  sx={{
                    width: "100%",
                    backgroundColor: "background.paper",
                    color: "text.primary",
                  }}
                  value={endDateFilter ? endDate : null}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => {
                    const formattedDate = dayjs(value)
                      .startOf("day")
                      .format("YYYY-MM-DD");
                    handleFilterChange(
                      "endDate",
                      formattedDate === "Invalid Date" ? null : formattedDate
                    );
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => option.country_name}
              value={
                countries.find((o) => o.country_id === selectedCountry) || null
              }
              onChange={(event, value) =>
                setSelectedCountry(value?.country_id || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Country" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={states}
              getOptionLabel={(option) => option.state_name}
              value={states.find((o) => o.state_id === selectedState) || null}
              onChange={(event, value) =>
                setSelectedState(value?.state_id || null)
              }
              renderInput={(params) => <TextField {...params} label="State" />}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={districts}
              getOptionLabel={(option) => option.district_name}
              value={
                districts.find((o) => o.district_id === selectedDistrict) ||
                null
              }
              onChange={(event, value) =>
                setSelectedDistrict(value?.district_id || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="District" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={companyProfiles}
              getOptionLabel={(option) => option.company_profile_name}
              value={
                companyProfiles.find(
                  (o) => o.company_profile_id === selectedCompany
                ) || null
              }
              onChange={(event, value) =>
                setSelectedCompany(value?.company_profile_id || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Company Profile" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={siteCategories}
              getOptionLabel={(option) => option.site_category_name}
              value={
                siteCategories.find(
                  (o) => o.site_category_id === selectedSiteCategory
                ) || null
              }
              onChange={(event, value) =>
                setSelectedSiteCategory(value?.site_category_id || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Site Category" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={mineMaterials}
              getOptionLabel={(option) => option.material_name}
              value={
                mineMaterials.find(
                  (o) => o.material_id === selectedMineMaterial
                ) || null
              }
              onChange={(event, value) =>
                setSelectedMineMaterial(value?.material_id || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Mine Material" />
              )}
            />
          </Grid>
          {console.log("selectedOwnership", selectedOwnership)}
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={ownerships}
              getOptionLabel={(option) => option.ownership_name}
              value={
                ownerships.find((o) => o.ownership_id === selectedOwnership) ||
                null
              }
              onChange={(event, value) =>
                setSelectedOwnership(value?.ownership_id || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Ownership" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Autocomplete
              options={operationStatus}
              getOptionLabel={(option) => option?.Name || null}
              value={
                operationStatus.find(
                  (o) => o.Name === selectedOperationStatus
                ) || null
              }
              onChange={(event, value) =>
                setSelectedOperationStatus(value?.Name || null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Operation Status" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#2196f3",
                "&:hover": {
                  borderColor: alpha("#2196f3", 0.8),
                },
                color: "#2196f3",
              }}
              onClick={clearFilter}>
              Clear Filter
            </Button>
          </Grid>
        </AppGridContainer>
      ) : null}
    </AppCard>
  );
};

ReportFilters.propTypes = {
  setStartDateFilter: PropTypes.func.isRequired,
  startDateFilter: PropTypes.string,
  setEndDateFilter: PropTypes.func.isRequired,
  endDateFilter: PropTypes.string,
  setStatusFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.string,
  setPage: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string,
  setSelectedCountry: PropTypes.func.isRequired,
  selectedState: PropTypes.string,
  setSelectedState: PropTypes.func.isRequired,
  selectedDistrict: PropTypes.string,
  setSelectedDistrict: PropTypes.func.isRequired,
  selectedCompany: PropTypes.string,
  setSelectedCompany: PropTypes.func.isRequired,
  selectedSiteCategory: PropTypes.string,
  setSelectedSiteCategory: PropTypes.func.isRequired,
  selectedMineMaterial: PropTypes.string,
  setSelectedMineMaterial: PropTypes.func.isRequired,
  selectedOwnership: PropTypes.string,
  setSelectedOwnership: PropTypes.func.isRequired,
  selectedOperationStatus: PropTypes.string,
  setSelectedOperationStatus: PropTypes.func.isRequired,
  isExpandFilter: PropTypes.bool.isRequired,
  setIsExpandFilter: PropTypes.func.isRequired,
};
export default ReportFilters;
