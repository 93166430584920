import InfoCard from "../InfoCard";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box, Typography } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import axios from "../../api";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { BusinessCenter, Description } from "@mui/icons-material";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
const libraries = ["places"];

const Settings = () => {
  const [assets, setAssets] = useState([]);
  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    try {
      const response = await axios.get("/assests/asset/status", {
        params: {
          filters: {
            type: "A",
          },
        },
      });
      const { data } = response;
      setAssets(data.list);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const defaultCenter = { lat: 17.46590051906, lng: 78.29023892319296 };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const mapStyles = {
    height: "90vh",
    width: "100%",
  };
  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        center={defaultCenter}
        options={{
          fullscreenControl: false, // Remove fullscreen button
          zoomControl: false, // Remove zoom control
          streetViewControl: false, // Remove street view pegman
          mapTypeControl: false, // Remove map type (satellite, terrain)
          rotateControl: false, // Remove rotate control
          scaleControl: false, // Remove scale indicator
          panControl: false, // Remove pan control
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
            "@media (max-width: 900px)": {
              flexDirection: "column",
              "& > *:not(:last-child)": { marginBottom: "10px" },
            },
          }}></Box>
        {assets && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              gap: "10px",
              margin: "5%",
              maxHeight: "70vh", // Adjust based on your needs
              overflowY: "auto",
              padding: "10px",
              position: "relative",
              "&::-webkit-scrollbar": {
                width: "2px",
                display: "none",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
              },
            }}>
            <InfoCard
              icon={Description}
              backgroundColor="#E8F5E9"
              iconColor="#ff0000"
              value={assets.total_license}
              description="Total License"
            />
            <InfoCard
              icon={BusinessCenter}
              backgroundColor="#E8F5E9"
              iconColor="#28a745"
              value={`${assets.total_assets}`}
              description="Total Assets"
            />
            <InfoCard
              icon={CropFreeRoundedIcon} // Use an appropriate icon
              backgroundColor="#E8F5E9"
              iconColor="#28a745"
              value={`Assets`}
              description={
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    width: "100%",
                  }}>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "text.secondary" }}>
                      Total
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "text.secondary" }}>
                      Free
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "text.secondary" }}>
                      Used
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "text.secondary" }}>
                      :
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "text.secondary" }}>
                      :
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "text.secondary" }}>
                      :
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {`${(
                        parseFloat(assets?.total_free_area) +
                        parseFloat(assets.total_used_area)
                      ).toFixed(2)} HA`}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {assets?.total_free_area} HA
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {assets.total_used_area} HA
                    </Typography>
                  </Box>
                </Box>
              }
            />
            {assets?.site_categories?.map((siteCategory) => (
              <InfoCard
                key={siteCategory.site_category_name}
                icon={CropFreeRoundedIcon} // Use an appropriate icon
                backgroundColor="#E8F5E9"
                iconColor="#28a745"
                value={`${siteCategory.site_category_name}`} // Total Area
                description={
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "text.secondary" }}>
                        Total
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "text.secondary" }}>
                        Free
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "text.secondary" }}>
                        Used
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "text.secondary" }}>
                        :
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "text.secondary" }}>
                        :
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", color: "text.secondary" }}>
                        :
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.primary" }}>
                        {`${(
                          parseFloat(siteCategory.free_area) +
                          parseFloat(siteCategory.used_area)
                        ).toFixed(2)} HA`}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.primary" }}>
                        {siteCategory.free_area} HA
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.primary" }}>
                        {siteCategory.used_area} HA
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            ))}
          </Box>
        )}
      </GoogleMap>
    )
  );
};

export default Settings;
