/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, Popconfirm, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css"; // Assuming custom styles
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Grid,
  Modal as MUIModal,
  TextField,
  Typography,
  Button as MuiButton,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import docTypeConstants from "../../constants/DocType.json";
import NotificatioPeriod from "../../constants/NotificationsPeriod.json"
import * as Yup from "yup";
import axios from "../../api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewDocument from "./ViewDocument";
import AppLoader from "../AppLoader";

const customScrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#44444461",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#666",
  },
};
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const commonValidationSchema = Yup.object({
  document_type: Yup.string().required("Document type is required"),
  date_of_doc: Yup.date().required("Date of Document is required"),
  document_number: Yup.string()
    .trim()
    .matches(/^\S+$/, "Document Number cannot contain spaces")
    .required("Document Number is required"),
  survey_number: Yup.string()
    .trim() // This will remove leading and trailing spaces but allow spaces within the string
    .nullable()
    .optional(),
  sro: Yup.string().nullable().optional(),
});
const assetValidationSchema = Yup.object({
  size_covered: Yup.number()
    .typeError("Size Covered must be a valid number") // Ensures the input is a number
    .required("Size Covered is required"),
});
const licenseValidationSchema = Yup.object({
  status: Yup.string().required("License status is required"),
});
const leaseValidationSchema = Yup.object({
  document_type: Yup.string().required("Document type is required"),
  date_of_doc: Yup.date().required("Date of Document is required"),
  document_number: Yup.string().required("Document Number is required"),
  size_covered: Yup.number()
    .typeError("Size Covered must be a valid number") // Ensures the input is a number
    .required("Size Covered is required"), // Marks it as a required field

  // document_renewal_date: Yup.date().required("Document Renewal Date is required"),
  sro: Yup.string().nullable().optional(),
  lease_ref_no: Yup.string().required("Lease Ref No. is required"), // Make lease_ref_no required
});

const validationEmailSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .typeError("Enter a valid email")
    .required("Email is required"),
  // link_expiry_on: Yup.date().required("Expiry date is required"),
  link_expiry_on: Yup.string()
    .required("Expiry date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format, expected DD-MM-YYYY")
    .test("is-valid-date", "Invalid date", (value) => {
      return !isNaN(new Date(value).getTime());
    }),
});

const UploadedDocuments = ({
  documentsData,
  fetchDocuments,
  assetId,
  isAsset,
  setAssetId,
}) => {
  const user = useSelector((state) => state.auth.user);
  const [dataSource, setDataSource] = useState(documentsData);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [document, setDocument] = useState(null);
  const [initialDocType, setInitialDocType] = useState(null);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLeaseRefSelected, setIsLeaseRefSelected] = useState(false);
  const [notificatioPeriod, setNotificatioPeriod] = useState(
    NotificatioPeriod.NotificatioPeriod
  );

  let showEdit = false;
  let showDelete = false;
  if (user?.user_role === 1) {
    showEdit = true;
    showDelete = true;
  } else if (user?.user_role === 2) {
    if (user?.user_permission.includes("Edit")) {
      showEdit = true;
    }
    if (user?.user_permission.includes("Delete")) {
      showDelete = true;
    }
  }
  useEffect(() => {
    let res = docTypeConstants;
    setDocTypeOptions(isAsset ? res.DocType : res.Licensedoc);
  }, []);
  const handleDelete = async (documentId) => {
    const newDataSource = dataSource.filter(
      (item) => item.document_id !== documentId
    );
    try {
      const response = await axios.delete(`documents/${documentId}`);
      setDataSource(newDataSource);
      setAssetId(assetId);
      fetchDocuments();
      message.success(response.data.message);
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  const handleShareOpen = () => {
    setShowModal(true);
  };
  const handleShareClose = () => {
    setShowModal(false);
  };

  const handleEditModalOpen = (data) => {
    setDocument(data);
    setShowEditModal(true);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
  };
  const handleViewModalOpen = (data) => {
    setDocument(data);
    setShowViewModal(true);
  };
  const handleViewModalClose = () => {
    setShowViewModal(false);
  };


  useEffect(() => {
    if (document && docTypeOptions.length > 0) {
      const matchingOption = docTypeOptions.find(
        (option) => option?.Name === document?.document_type
      );
      setInitialDocType(matchingOption || null);
      setIsLeaseRefSelected(matchingOption?.Name === "Lease deed");
    }
  }, [document, docTypeOptions]);

  const columns = [
    {
      name: "index",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "document_type",
      label: "Doc Type",
    },
    {
      name: "date_of_doc",
      label: "Date Of Doc",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return convert(value);
        },
      },
    },
    {
      name: "document_number",
      label: "Doc Number",
    },
    {
      name: "document_renewal_date",
      label: "Renewal Date",
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value === null) {
            return "N/A"; // Or any other placeholder for null values
          }
          return convert(value);
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { currentTableData, rowIndex } = tableMeta;
          const data = tableMeta?.tableData[currentTableData[rowIndex]?.index];
          return (
            <div style={{ display: "flex" }}>
              {showDelete && (
                <Popconfirm
                  title="Are you sure delete this record?"
                  onConfirm={() => handleDelete(data?.document_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
              {showEdit && (
                // <Popconfirm
                //   title="Are you sure Edit this record?"
                //   onConfirm={() => alert(`Editing record: ${documentNumber}`)}
                //   okText="Yes"
                //   cancelText="No"
                // >
                <Button
                  onClick={() => handleEditModalOpen(data)}
                  icon={<EditOutlined />}
                />
                // </Popconfirm>
              )}
              <Grid sx={{ marginLeft: "10px" }}>
                <Button
                  onClick={() => handleViewModalOpen(data)}
                  icon={<VisibilityIcon />}
                />
              </Grid>
            </div>
          );
        },
      },
    },
  ];

  if (isAsset) {
    columns.splice(
      4,
      0, // Add columns after "Doc Number"
      {
        name: "survey_number",
        label: "Survey Number",
      },
      {
        name: "size_covered",
        label: "Size Covered",
      }
    );
  } else {
    columns.splice(
      4,
      0, // Add column after "Doc Number"
      {
        name: "status",
        label: "License Status",
      }
    );
  }
  const convert = (dateVal) => {
    let date = new Date(dateVal),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  const options = {
    rowStyle: (rowData) => {
      const isExpired = rowData.is_expired;
      const isNotified = rowData.is_notified;
      if (isExpired) {
        return { backgroundColor: "lightcoral" };
      }
      if (isNotified) {
        return { backgroundColor: "lightgoldenrodyellow" };
      }
      return {};
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    download: false,
    viewColumns: true,
    selectableRows: "single", // Allow single row selection
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      const selectedRowData = allRowsSelected.map(
        (row) => documentsData && documentsData[row?.dataIndex]
      );
      setSelectedRows(selectedRowData); // Update state with the selected row data
    },
    customToolbarSelect: () => null, // Removes the default toolbar actions
    setRowProps: (row) => {
      const isExpired = row.is_expired; // Access `is_expired` field
      const isNotified = row.is_notified; // Access `is_notified` field
      // Determine the background color based on the status
      const backgroundColor = isExpired
        ? "#FFCCCC" // Light red for expired rows
        : isNotified
        ? "#FFE5CC" // Light orange for notified rows
        : "transparent"; // Default background for other rows
  
      return {
        style: { backgroundColor }, // Apply the background color
      };
    },
  };
  

  const handleShare = async (values, { resetForm }) => {
    setLoading(true);
    try {
      setLoading(true);
      const response = await axios.post("/file/send-file-link", {
        email: values.email,
        link_expiry_on: values.link_expiry_on,
        document_id: selectedRows[0]?.document_id,
        file_name: selectedRows[0]?.file_name,
      });
      if (response.status === 200) {
        resetForm();
        handleShareClose();
        setLoading(false);
        message.success(response.data.message);
      }
    } catch (error) {
      message.error(error.response.data.error);
      setLoading(false);
      console.error("Error:", error);
    }
  };
  
  const today = new Date().toISOString().split("T")[0];
  const validationSchema = () => {
    if (isAsset) {
      return commonValidationSchema.concat(assetValidationSchema);
    } else {
      return commonValidationSchema.concat(licenseValidationSchema);
    }
  };
  return (
    <div className="map-form-fields">
      <Container fluid>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mb={2}
        >
          {/* <Button onClick={handleClearSelection} style={{ marginRight: 16 }}>
            Clear Selection
          </Button> */}
          <Button
            onClick={handleShareOpen}
            style={{
              backgroundColor: " #330066",
              color: "white",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
            disabled={selectedRows?.length === 0}
          >
            Share documents
          </Button>
        </Box>

        {showModal && (
          <MUIModal open={showModal} onClose={handleShareClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: "8px",
                boxShadow: 24,
                p: 4,
                maxWidth: 500,
                maxHeight: "calc(100vh - 100px)",
                overflow: "auto",
                ...customScrollbarStyle,
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: "10px" }}
              >
                Share Document
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                Are you sure you want to share these documents with given email
                user?
              </Typography>
              <Formik
                initialValues={{ email: "", link_expiry_on: "" }}
                validationSchema={validationEmailSchema}
                onSubmit={handleShare}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <Grid item xs={12} mmd={6} sm={6} sx={{ marginBottom: 3 }}>
                      <Field
                        as={TextField}
                        fullWidth
                        id="email"
                        name="email"
                        label={
                          <span>
                            Email
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        variant="outlined"
                        onChange={(e) => setFieldValue("email", e.target.value)}
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Field
                        label={
                          <span>
                            Expiry Date
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        name="link_expiry_on"
                        as={TextField}
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            min: today, // Disable past dates
                          },
                        }}
                        error={errors.link_expiry_on && touched.link_expiry_on}
                        helperText={
                          touched.link_expiry_on && errors.link_expiry_on
                        }
                      />
                    </Grid>
                    <Box mt={4} mb={1}>
                      <MuiButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        sx={{
                          minWidth: 160,
                          fontSize: 16,
                          textTransform: "capitalize",
                          // padding: "8px 16px 8px",
                        }}
                      >
                        Enter
                      </MuiButton>
                    </Box>
                  </Form>
                )}
              </Formik>
              {loading && <AppLoader />} {/* Conditionally render loader */}
            </Box>
          </MUIModal>
        )}

        {showEditModal && (
          <MUIModal open={showEditModal} onClose={handleEditModalClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: "8px",
                boxShadow: 24,
                p: 4,
                maxWidth: 600,
                maxHeight: "calc(100vh - 100px)",
                overflow: "auto",
                ...customScrollbarStyle,
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: "10px" }}
              >
                Edit Document
              </Typography>
              <Formik
                initialValues={{
                  document_type: document?.document_type || null,
                  date_of_doc: formatDate(document?.date_of_doc) || null,
                  document_number: document?.document_number || null,
                  survey_number: document?.survey_number || "",
                  size_covered: document?.size_covered || null,
                  document_renewal_date: document?.document_renewal_date
                    ? formatDate(document.document_renewal_date)
                    : null,
                  sro: document?.sro || "",
                  assest_id: document?.assest_id || null,
                  lease_ref_no: document?.lease_ref_no || null,
                  document_expiry_in_days:
                    document?.document_expiry_in_days || null,
                  status: document?.status || null,
                  notification_days: document?.notification_days || null,
                }}
                validationSchema={
                  isLeaseRefSelected
                    ? leaseValidationSchema
                    : validationSchema()
                }
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                  const transformedValues = {
                    ...values,
                    document_renewal_date:
                      values.document_renewal_date === ""
                        ? null
                        : values.document_renewal_date,
                    date_of_doc:
                      values.date_of_doc === "" ? null : values.date_of_doc,
                  };
                  try {
                    const response = await axios.put(
                      `/documents/edit-document/${document?.document_id}`,
                      transformedValues
                    );
                    fetchDocuments();
                    resetForm();
                    setAssetId(document?.assest_id);
                    handleEditModalClose();
                    message.success(response.data.message);

                    // AttachDocIdToUploadedDocument(response.id[0]);
                  } catch (error) {
                    message.error(error.response.data.error);
                  }
                }}
              >
                {({ setFieldValue, values, touched, errors, resetForm }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          id="docType"
                          options={docTypeOptions}
                          getOptionLabel={(option) => option.Name || ""}
                          value={
                            docTypeOptions.find(
                              (option) => option.Name === values.document_type
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            setIsLeaseRefSelected(
                              newValue?.Name === "Lease deed"
                            );
                            setFieldValue(
                              "document_type",
                              newValue ? newValue.Name : ""
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Doc Type
                                  <span
                                    style={{ color: "red", marginLeft: "4px" }}
                                  >
                                    *
                                  </span>
                                </span>
                              }
                              variant="outlined"
                              error={
                                touched.document_type &&
                                Boolean(errors.document_type)
                              }
                              helperText={
                                touched.document_type && errors.document_type
                              }
                            />
                          )}
                        />
                      </Grid>
                      {values?.document_type === "Lease deed" && (
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            fullWidth
                            label={
                              <span>
                                Lease Ref No.
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            name="lease_ref_no"
                            value={values.lease_ref_no}
                            onChange={(e) =>
                              setFieldValue("lease_ref_no", e.target.value)
                            }
                            error={
                              touched.lease_ref_no &&
                              Boolean(errors.lease_ref_no)
                            }
                            helperText={
                              touched.lease_ref_no && errors.lease_ref_no
                            }
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={
                            <span>
                              Document Number
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="document_number"
                          value={values.document_number}
                          onChange={(e) =>
                            setFieldValue("document_number", e.target.value)
                          }
                          error={
                            touched.document_number &&
                            Boolean(errors.document_number)
                          }
                          helperText={
                            touched.document_number && errors.document_number
                          }
                        />
                      </Grid>
                      {isAsset && (
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="Survey Numbers"
                              name="survey_number"
                              value={values.survey_number}
                              onChange={(e) =>
                                setFieldValue("survey_number", e.target.value)
                              }
                              error={
                                touched.survey_number &&
                                Boolean(errors.survey_number)
                              }
                              helperText={
                                touched.survey_number && errors.survey_number
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label={
                                <span>
                                  Size Covered
                                  <span
                                    style={{ color: "red", marginLeft: "4px" }}
                                  >
                                    *
                                  </span>
                                </span>
                              }
                              name="size_covered"
                              value={values.size_covered}
                              type="number"
                              step="any"
                              onChange={(e) =>
                                setFieldValue("size_covered", e.target.value)
                              }
                              error={
                                touched.size_covered &&
                                Boolean(errors.size_covered)
                              }
                              helperText={
                                touched.size_covered && errors.size_covered
                              }
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={6}>
                        <Grid container>
                          <Field
                            label={
                              <span>
                                Date of Document
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            name="date_of_doc"
                            as={TextField}
                            type="date"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={errors.date_of_doc && touched.date_of_doc}
                            helperText={
                              touched.date_of_doc && errors.date_of_doc
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container>
                          <Field
                            name="document_renewal_date"
                            label="Document Renewal Date"
                            as={TextField}
                            type="date"
                            fullWidth
                            variant="outlined"
                            error={
                              errors.document_renewal_date &&
                              touched.document_renewal_date
                            }
                            helperText={
                              touched.document_renewal_date &&
                              errors.document_renewal_date
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      {isAsset && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="sro"
                            name="sro"
                            value={values.sro}
                            onChange={(e) =>
                              setFieldValue("sro", e.target.value)
                            }
                            error={touched.sro && Boolean(errors.sro)}
                            helperText={touched.sro && errors.sro}
                          />
                        </Grid>
                      )}
                      {!isAsset && (
                        <>
                          <Grid item xs={12} md={6} sm={6}>
                            <FormControl fullWidth>
                              <TextField
                                select
                                name="status"
                                value={values.status}
                                label={
                                  <span>
                                    License status
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: "4px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </span>
                                }
                                error={touched.status && Boolean(errors.status)}
                                helperText={touched.status && errors.status}
                                onChange={(event) => {
                                  setFieldValue("status", event.target.value); // Update the form field value
                                }}
                              >
                                <MenuItem value={"Granted"}>Granted</MenuItem>
                                <MenuItem value={"Applied for"}>
                                  Applied for
                                </MenuItem>
                                <MenuItem value={"Not applied"}>
                                  Not applied
                                </MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} sm={6}>
                            <Autocomplete
                              id="notification_days"
                              options={notificatioPeriod}
                              getOptionLabel={(option) => option.Name || null}
                              value={
                                notificatioPeriod.find(
                                  (option) =>
                                    option.Id === values.notification_days
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                setFieldValue(
                                  "notification_days",
                                  newValue ? newValue.Id : null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Notification Month"
                                  variant="outlined"
                                  error={
                                    touched.document_type &&
                                    Boolean(errors.document_type)
                                  }
                                  helperText={
                                    touched.document_type &&
                                    errors.document_type
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <MuiButton
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Save
                          </MuiButton>
                        </Grid>
                        <Grid item>
                          <MuiButton
                            variant="contained"
                            onClick={() => {
                              resetForm(); // Resets the form to its initial values
                              setShowEditModal(false); // Closes the modal
                            }}
                          >
                            Cancel
                          </MuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </MUIModal>
        )}
        {showViewModal && (
          <ViewDocument
            isOpen={showViewModal}
            onClose={handleViewModalClose}
            document={document}
          />
        )}
        <MUIDataTable
          className="m-super-admin"
          title={isAsset ? "Assets Documents" : "License Documents"}
          data={documentsData}
          columns={columns}
          options={options}
        />
      </Container>
    </div>
  );
};

export default UploadedDocuments;
