import React, { useEffect, useState } from "react";
import {
  Modal as MUIModal,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Container,
  Grid,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "../../api";
import { message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";

dayjs.extend(utc);

// Define a custom validation method for the email array
const validationSchema = Yup.object({
  assest_id: Yup.array().min(1, "Please choose at least one asset"),
  document_id: Yup.array().min(1, "Please choose at least one document"),
});

const LinkAssetModal = ({ open, onClose, users, license_id, fetchAssets }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);

  const fetchDocuments = async () => {
    const asset_ids = selectedUsers.map((member) => member.assest_id);
    try {
      if (asset_ids.length > 0) {
        const response = await axios.get(`/documents`, {
          params: {
            asset_ids,
            show_all: false,
          },
        });
        const { data } = response;
        const docData = data.list;
        setDocuments(docData);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [selectedUsers]);

  const handleClose = () => {
    setSelectedUsers([]);
    onClose();
  };
  return (
    <div className="map-form-fields">
      <Container fluid>
        <MUIModal open={open} onClose={handleClose}>
          <Box className="edit_doc">
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "10px" }}>
              Link assets
            </Typography>
            <Formik
              initialValues={{
                assest_id: [],
                document_id: [],
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                // Convert time_of_day to UTC if cron is enabled

                try {
                  if (license_id) {
                    // Send email data
                    await axios.post(`/assests/linked-assets/${license_id}`, {
                      values,
                    });

                    message.success("Assets linked successfully");
                    fetchAssets();
                    handleClose(); // Close the modal after submission}
                  } else {
                    message.error(
                      "License not found. Please add license first"
                    );
                  }
                } catch (error) {
                  message.error(error?.response?.data?.error);
                  console.error("Error while linking assets:", error);
                }
              }}>
              {({
                values,
                setFieldValue,
                errors,
                touched,
                resetForm,
                isSubmitting,
                setFieldTouched,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        multiple
                        id="free-solo-2-demo"
                        disableClearable
                        options={(users || []).sort((a, b) =>
                          a.assest_name.localeCompare(b.assest_name)
                        )}
                        value={selectedUsers}
                        onChange={(event, newValue) => {
                          const uniqueMembers = newValue.filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.assest_id === value.assest_id
                              ) // Keep unique IDs only
                          );
                          setSelectedUsers(uniqueMembers);
                          setFieldValue(
                            "assest_id",
                            uniqueMembers.map((member) => member.assest_id)
                          );
                        }}
                        // onInputChange={(_, value) => {
                        //   setSearchQuery(value);
                        // }}
                        onBlur={() => setFieldTouched("assest_id", true)}
                        getOptionLabel={(option) => `${option.assest_name}`}
                        renderOption={(optionData, option) => {
                          optionData.key = optionData.assest_id;
                          return (
                            <li {...optionData}>{`${option.assest_name}`}</li>
                          );
                        }}
                        error={errors.assest_id}
                        helperText={errors.assest_id ? "Asset is required" : ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Assets"
                            name="assest_id"
                            value={values.assest_id}
                            error={errors.assest_id}
                            helperText={errors.assest_id}
                            sx={{
                              mt: 4,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        multiple
                        id="free-solo-2-demo"
                        disableClearable
                        options={(documents || []).sort((a, b) =>
                          a.survey_number.localeCompare(b.survey_number)
                        )}
                        value={selectedDocuments}
                        onChange={(event, newValue) => {
                          const uniqueMembers = newValue.filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.document_id === value.document_id
                              ) // Keep unique IDs only
                          );
                          setSelectedDocuments(uniqueMembers);
                          setFieldValue(
                            "document_id",
                            uniqueMembers.map((member) => ({
                              assest_id: member.assest_id,
                              document_id: member.document_id,
                            }))
                          );
                        }}
                        // onInputChange={(_, value) => {
                        //   setSearchQuery(value);
                        // }}
                        onBlur={() => setFieldTouched("document_id", true)}
                        getOptionLabel={(option) => `${option.survey_number} - ${option.document_number}`}
                        renderOption={(optionData, option) => {
                          optionData.key = optionData.document_id;
                          return (
                            <li {...optionData}>{`${option.survey_number} - ${option.document_number}`}</li>
                          );
                        }}
                        error={errors.document_id}
                        helperText={errors.document_id ? "Document is required" : ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Documents"
                            name="document_id"
                            value={values.document_id}
                            error={errors.document_id}
                            helperText={errors.document_id}
                            sx={{
                              mt: 4,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      item
                      xs={12}
                      md={12}
                      sm={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                        disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Link"}
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          handleClose();
                        }}
                        variant="outlined">
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </MUIModal>
      </Container>
    </div>
  );
};
LinkAssetModal.propTypes = {
  open: PropTypes.bool, // Assuming open is a boolean for modal visibility
  onClose: PropTypes.func, // Assuming onClose is a function to close the modal
  users: PropTypes.array, // Assuming users is an array of user objects
  license_id: PropTypes.string, // Assuming license_id is a string
  fetchAssets: PropTypes.func, // Assuming fetchAssets is a function to fetch assets
};
export default LinkAssetModal;
