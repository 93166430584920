/* eslint-disable */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import "./index.css";
import {
  TextField,
  MenuItem,
  FormControl,
  Grid,
  Autocomplete,
  Typography,
  Box,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../../api";
import { message } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import NotificatioPeriod from "../../constants/NotificationsPeriod.json";
import { isBefore, subMonths, parseISO } from "date-fns";

const assetSchema = Yup.object().shape({
  emergency_contact_phone: Yup.string()
    .matches(/^\d+$/, "Emergency Contact Phone must be numeric only")
    .length(10, "Emergency Contact Phone must be exactly 10 digits")
    .nullable(),
  company_owned_by: Yup.string().required("Company Ownership is required"),
  owner_ship_type: Yup.string().required("Ownership type is required"),
});
const licenceSchema = Yup.object().shape({
  start_date: Yup.date().required("License Start Date is required"),
  end_date: Yup.date()
    .nullable()
    .optional()
    .test(
      "endDate",
      "End Date must be greater than Start Date",
      function (value) {
        const { start_date } = this.parent;
        return !value || !start_date || value > start_date;
      }
    ),
  status: Yup.string().required("Status is required"),
  operation_status: Yup.string().required("Operation Status is required"),
  survey_number: Yup.string()
    .trim() // This will remove leading and trailing spaces but allow spaces within the string
    .required("Survey Number is required"),
  notification_months: Yup.number()
    .nullable()
    .test(
      "valid-notification",
      "Notification date must not be in the past",
      function (value) {
        const { end_date } = this.parent;
        if (end_date && value) {
          // Ensure end_date is a Date object
          const renewalDate =
            typeof end_date === "string" ? parseISO(end_date) : end_date;

          // Calculate the notification date
          const notificationDateLicense = subMonths(renewalDate, value);

          // Check if the calculated date is in the past
          return !isBefore(notificationDateLicense, new Date());
        }
        return true; // Skip validation if end_date is empty
      }
    ),
});

const commanVaildation = Yup.object().shape({
  assest_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9 ]+$/,
      "Asset Name should only contain alphanumeric characters(no special characters)"
    ) // Allow alphanumeric characters and spaces
    .required("Asset Name is required"),
  address: Yup.string().required("Address is required"),
  site_category_id: Yup.string().required("Site Category is required"),
  site_size: Yup.number().required("Site Size is required"),
  country_id: Yup.string().required("Country is required"),
  state_id: Yup.string().when("country_id", {
    is: "1", // value of 'India'
    then: () => Yup.string().required("State is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  district_id: Yup.string().when("country_id", {
    is: "1", // value of 'India'
    then: () => Yup.string().required("District is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  village: Yup.string().required("City/Village is required"),
  pin: Yup.string()
    .required("Pin Code is required")
    .matches(/^\d{5,6}$/, "Pin Code must be 5 or 6 digits"),
  mine_material: Yup.string().required("Mine Material is required"),
});
const notificationDate = (end_date, value) => {
  const renewalDate =
    typeof end_date === "string" ? parseISO(end_date) : end_date;

  // Calculate the notification date
  const notificationDates = subMonths(renewalDate, value);
  return convert(notificationDates);
};
const convert = (dateVal) => {
  let date = new Date(dateVal),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};
const validationSchema = (isAsset) => {
  if (isAsset) {
    return commanVaildation.concat(assetSchema);
  } else {
    return commanVaildation.concat(licenceSchema);
  }
};

const AssetForm = (props) => {
  const {
    setFormVisible,
    setSelectedLocation,
    isEditMode,
    setIsEditMode,
    isAsset,
    setAssetId,
    setIsAsset,
  } = props;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    props.tempFormData?.country_id || null
  );
  const [selectedState, setSelectedState] = useState(
    props.tempFormData?.state_id || null
  );
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [editOnExistingAssest, setEditOnExistingAssest] = useState(false);
  const [fetchSiteCategories, setFetchSiteCategories] = useState([]);
  const [fetchMineMaterial, setFetchMineMaterial] = useState([]);
  const [fetchCompanyData, setFetchCompanyData] = useState([]);
  const [ownerShipData, setOwnerShipData] = useState([]);
  const notificatioPeriod = NotificatioPeriod.NotificatioPeriod;
  const operationStatus = NotificatioPeriod.operationStatus;
  const onClickExistingCancel = () => {
    setEditOnExistingAssest(false);
  };

  const handleassestEdit = async (assestId, values) => {
    values.geolocation = JSON.stringify(values.geolocation);
    values.end_date =
      values?.end_date != "" ? formatDate(values?.end_date) : null;
    try {
      const response = await axios.put(
        `/assests/edit-assest/${assestId}`,
        values
      );
      if (response.status === 201) {
        const messagetext = isAsset ? "Asset" : "License";
        message.success(`${messagetext} updated successfully`);
        setTimeout(() => {
          props.moveToNextTab(response.data.id[0]);
        }, 1000);
        setAssetId(assestId);
        setIsAsset(isAsset === true);
      }
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  const handleAssetDelete = async (assestId) => {
    try {
      const response = await axios.delete(`/assests/${assestId}`);
      setFormVisible(false);
      setSelectedLocation(null);
      message.success(response.data.message);
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  //Fetch Country
  const fetchCountry = async () => {
    try {
      const response = await axios.get(`/countries`);
      const { data } = response;
      setCountries(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  //Fetch Statces
  useEffect(() => {
    fetchStates();
  }, [selectedCountry]);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`/states/country/${selectedCountry}`);
      const { data } = response;
      setStates(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  //Fetch district
  useEffect(() => {
    fetchDistrict();
  }, [selectedState]);

  const fetchDistrict = async () => {
    try {
      const response = await axios.get(`/districts/state/${selectedState}`);

      const { data } = response;
      setDistrict(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const fetchSiteCategoiresData = async () => {
    try {
      const response = await axios.get("/site-categories", {
        params: {
          filters: {
            type: isAsset ? "A" : "L",
          },
        },
      });
      const { data } = response;
      setFetchSiteCategories(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchSiteCategoiresData();
    fetchMineMaterialData();
    fetchCompanyProfileData();
    fetchOwnerShipData();
  }, []);

  const fetchMineMaterialData = async () => {
    try {
      const response = await axios.get("/mine-material");
      const { data } = response;
      setFetchMineMaterial(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // useEffect(() => {

  // }, []);
  const fetchCompanyProfileData = async () => {
    try {
      const response = await axios.get("/company-profile");
      const { data } = response;
      setFetchCompanyData(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // useEffect(() => {

  // }, []);

  const fetchOwnerShipData = async () => {
    try {
      const response = await axios.get("/owner-ship");
      const { data } = response;
      setOwnerShipData(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  // useEffect(() => {

  // }, []);
  useEffect(() => {
    if (!props.tempFormData?.assest_id) {
      setIsEditMode(true);
    }
  }, [props.tempFormData?.assest_id]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div className="map-form-fields">
      {isEditMode ? (
        <Formik
          validationSchema={validationSchema(isAsset)}
          enableReinitialize
          initialValues={{
            assest_name: props.tempFormData?.assest_name || null,
            geolocation: props.tempFormData?.geolocation
              ? JSON.parse(props.tempFormData?.geolocation)
              : props.selectedLocationFromParent || {},
            address: props.tempFormData?.address || null,
            emergency_contact_person:
              props.tempFormData?.emergency_contact_person || null,
            emergency_contact_phone:
              props.tempFormData?.emergency_contact_phone || null,
            site_category_id: props.tempFormData?.site_category_id || null,
            mine_material: props.tempFormData?.mine_material || null,
            site_size: props.tempFormData?.site_size || null,
            company_owned_by: props.tempFormData?.company_owned_by || null,
            owner_ship_type: props.tempFormData?.owner_ship_type || null,
            country_id: props.tempFormData?.country_id || null,
            state_id: props.tempFormData?.state_id || null,
            district_id: props.tempFormData?.district_id || null,
            village: props.tempFormData?.village || null,
            pin: props.tempFormData?.pin || null,
            survey_number: props.tempFormData?.survey_number || null,
            status: props.tempFormData?.status || null,
            start_date: props.tempFormData?.start_date
              ? formatDate(props.tempFormData?.start_date)
              : null,
            end_date: props.tempFormData?.end_date
              ? formatDate(props.tempFormData?.end_date)
              : null,
            type: isAsset ? "A" : "L",
            notification_months:
              props.tempFormData?.notification_months || null,
            notification_date: props.tempFormData?.notification_date
              ? formatDate(props.tempFormData?.notification_date)
              : null,
            operation_status: props.tempFormData?.operation_status || null,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const transformedValues = {
                ...values,
                end_date: values.end_date != "" ? values.end_date : null,
                notification_date:
                  values.end_date && values.notification_months
                    ? notificationDate(
                        values.end_date,
                        values.notification_months
                      )
                    : null,
              };
              const response = await axios.post(`/assests/add-assest`, [
                transformedValues,
              ]);
              if (response.status === 201) {
                const messagetext = isAsset ? "Asset" : "License";
                message.success(`${messagetext} Saved successfully`);
                setTimeout(() => {
                  props.moveToNextTab(response.data.id[0]);
                }, 2000);
              }
              setSubmitting(false);
            } catch (error) {
              console.log("error", error);
              message.error(
                error?.response?.data?.error || error?.response?.error || error
              );
            } finally {
              setSubmitting(false);
            }
          }}>
          {({ setFieldValue, values, touched, errors, validateField }) => {
            return (
              <Form className="row form-style">
                <Grid container spacing={2}>
                  {props.tempFormData?.assest_id && !isEditMode && (
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsEditMode(true)}>
                      <EditIcon />
                      <Typography variant="body2" ml={1}>
                        Edit
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          {isAsset ? "Site Name" : "License Name"}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="assest_name"
                      value={values.assest_name}
                      //   onChange={handleChange}
                      onChange={(e) =>
                        setFieldValue("assest_name", e.target.value)
                      }
                      error={touched.assest_name && Boolean(errors.assest_name)}
                      helperText={
                        touched.assest_name &&
                        errors.assest_name &&
                        (isAsset
                          ? "Site Name is required"
                          : "License Name is required")
                      }
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Geo Location"
                      value={`${values.geolocation?.lat || null}, ${
                        values.geolocation?.lng || null
                      }`}
                      disabled
                    />
                  </Grid>
                  {!isAsset && (
                    <>
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          fullWidth
                          label={
                            <span>
                              Survey Number{""}
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="survey_number"
                          value={values.survey_number}
                          onChange={(e) =>
                            setFieldValue("survey_number", e.target.value)
                          }
                          error={
                            touched.survey_number &&
                            Boolean(errors.survey_number)
                          }
                          helperText={
                            touched.survey_number && errors.survey_number
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            disabled={
                              !isEditMode && props.tempFormData?.assest_id
                            }
                            select
                            name="status"
                            value={values.status}
                            label={
                              <span>
                                License status{""}
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}>
                                  *
                                </span>
                              </span>
                            }
                            error={touched.status && Boolean(errors.status)}
                            helperText={touched.status && errors.status}
                            onChange={(event) => {
                              setFieldValue("status", event.target.value); // Update the form field value
                            }}>
                            <MenuItem value={"Granted"}>Granted</MenuItem>
                            <MenuItem value={"Applied for"}>
                              Applied for
                            </MenuItem>
                            <MenuItem value={"Not applied"}>
                              Not applied
                            </MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <Field
                          label={
                            <span>
                              License Start Date{""}
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          name="start_date"
                          as={TextField}
                          type="date"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={errors.start_date && touched.start_date}
                          helperText={touched.start_date && errors.start_date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <Field
                          name="end_date"
                          label="License End Date"
                          as={TextField}
                          type="date"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={errors.end_date && touched.end_date}
                          helperText={touched.end_date && errors.end_date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <Autocomplete
                          id="operation_status"
                          options={operationStatus}
                          getOptionLabel={(option) => option.Name || null}
                          value={
                            operationStatus.find(
                              (option) =>
                                option.Name === values.operation_status
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "operation_status",
                              newValue ? newValue.Name : null
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Operation Status{""}
                                  <span
                                    style={{ color: "red", marginLeft: "4px" }}>
                                    *
                                  </span>
                                </span>
                              }
                              variant="outlined"
                              error={
                                touched.operation_status &&
                                Boolean(errors.operation_status)
                              }
                              helperText={
                                touched.operation_status &&
                                errors.operation_status
                              }
                            />
                          )}
                        />
                      </Grid>

                      {values.end_date && (
                        <Grid item xs={12} md={6} sm={6}>
                          <Autocomplete
                            id="notification_months"
                            options={notificatioPeriod}
                            getOptionLabel={(option) => option.Name || null}
                            value={
                              notificatioPeriod.find(
                                (option) =>
                                  option.Id === values.notification_months
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "notification_months",
                                newValue ? newValue.Id : null
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Notification Month"
                                variant="outlined"
                                error={
                                  touched.notification_months &&
                                  Boolean(errors.notification_months)
                                }
                                helperText={
                                  touched.notification_months &&
                                  errors.notification_months
                                }
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          {isAsset ? "Address" : "Location"}{" "}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="address"
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      error={touched.address && Boolean(errors.address)}
                      helperText={
                        touched.address &&
                        errors.address &&
                        (isAsset
                          ? "Address is required"
                          : "Location is required")
                      }
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  {isAsset && (
                    <>
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          fullWidth
                          label="Emergency Contact Person"
                          name="emergency_contact_person"
                          value={values.emergency_contact_person}
                          onChange={(e) =>
                            setFieldValue(
                              "emergency_contact_person",
                              e.target.value
                            )
                          }
                          error={
                            touched.emergency_contact_person &&
                            Boolean(errors.emergency_contact_person)
                          }
                          helperText={
                            touched.emergency_contact_person &&
                            errors.emergency_contact_person
                          }
                          disabled={
                            !isEditMode && props.tempFormData?.assest_id
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          fullWidth
                          label="Emergency Contact Phone"
                          name="emergency_contact_phone"
                          value={values.emergency_contact_phone}
                          onChange={(e) =>
                            setFieldValue(
                              "emergency_contact_phone",
                              e.target.value
                            )
                          }
                          error={
                            touched.emergency_contact_phone &&
                            Boolean(errors.emergency_contact_phone)
                          }
                          helperText={
                            touched.emergency_contact_phone &&
                            errors.emergency_contact_phone
                          }
                          disabled={
                            !isEditMode && props.tempFormData?.assest_id
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="site_category_id"
                        value={values.site_category_id}
                        label={
                          <span>
                            Site Category{""}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={
                          touched.site_category_id &&
                          Boolean(errors.site_category_id)
                        }
                        helperText={
                          touched.site_category_id && errors.site_category_id
                        }
                        onChange={(event) => {
                          setFieldValue("site_category_id", event.target.value); // Update the form field value
                        }}>
                        {fetchSiteCategories
                          .filter((country) => country.is_active !== 0)
                          .map((country) => (
                            <MenuItem
                              key={country.site_category_id}
                              value={country.site_category_id}>
                              {country.site_category_name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="mine_material"
                        value={values.mine_material}
                        label={
                          <span>
                            Mine Material{""}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={
                          touched.mine_material && Boolean(errors.mine_material)
                        }
                        helperText={
                          touched.mine_material && errors.mine_material
                        }
                        onChange={(event) => {
                          setFieldValue("mine_material", event.target.value); // Update the form field value
                        }}>
                        {fetchMineMaterial
                          .filter((country) => country.is_active !== 0)
                          .map((country) => (
                            <MenuItem
                              key={country.material_id}
                              value={country.material_id}>
                              {country.material_name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          {isAsset ? "Site Size" : "Extent(Ha)"}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="site_size"
                      type="number"
                      value={values.site_size}
                      onChange={(e) =>
                        setFieldValue("site_size", e.target.value)
                      }
                      error={touched.site_size && Boolean(errors.site_size)}
                      helperText={
                        touched.site_size &&
                        errors.site_size &&
                        (isAsset
                          ? "Site Size is required"
                          : "Extent(Ha) is required")
                      }
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  {isAsset && (
                    <>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            disabled={
                              !isEditMode && props.tempFormData?.assest_id
                            }
                            select
                            name="company_owned_by"
                            value={values.company_owned_by}
                            label={
                              <span>
                                Company Owned By{""}
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}>
                                  *
                                </span>
                              </span>
                            }
                            error={
                              touched.company_owned_by &&
                              Boolean(errors.company_owned_by)
                            }
                            helperText={
                              touched.company_owned_by &&
                              errors.company_owned_by
                            }
                            onChange={(event) => {
                              setFieldValue(
                                "company_owned_by",
                                event.target.value
                              ); // Update the form field value
                            }}>
                            {fetchCompanyData.map((country) => (
                              <MenuItem
                                key={country.company_profile_id}
                                value={country.company_profile_id}>
                                {country.company_profile_name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            disabled={
                              !isEditMode && props.tempFormData?.assest_id
                            }
                            select
                            name="owner_ship_type"
                            value={values.owner_ship_type}
                            label={
                              <span>
                                Owner Ship{""}
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}>
                                  *
                                </span>
                              </span>
                            }
                            error={
                              touched.owner_ship_type &&
                              Boolean(errors.owner_ship_type)
                            }
                            helperText={
                              touched.owner_ship_type && errors.owner_ship_type
                            }
                            onChange={(event) => {
                              setFieldValue(
                                "owner_ship_type",
                                event.target.value
                              ); // Update the form field value
                            }}>
                            {ownerShipData.map((country) => (
                              <MenuItem
                                key={country.ownership_id}
                                value={country.ownership_id}>
                                {country.ownership_name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!isEditMode && props.tempFormData?.assest_id}
                        select
                        name="country_id"
                        value={values.country_id}
                        label={
                          <span>
                            Country{""}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={touched.country_id && Boolean(errors.country_id)}
                        helperText={touched.country_id && errors.country_id}
                        onChange={(event) => {
                          setSelectedCountry(event.target.value);
                          setFieldValue("country_id", event.target.value);
                        }}>
                        {countries.map((country) => (
                          <MenuItem
                            key={country.country_id}
                            value={country.country_id}>
                            {country.country_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      disabled={
                        !values.country_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="state-autocomplete"
                      options={states}
                      getOptionLabel={(option) => option.state_name || null}
                      value={
                        states.find(
                          (state) => state.state_id === values.state_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        const stateId = newValue ? newValue.state_id : null;
                        setSelectedState(stateId);
                        setFieldValue("state_id", stateId);

                        // Clear district and trigger validation
                        if (!stateId) {
                          setFieldValue("district_id", null);
                        } else {
                          // Set district_id to empty and trigger validation if needed
                          setFieldValue("district_id", null);
                          // Optionally trigger validation manually
                          validateField("district_id");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              State{""}
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          variant="outlined"
                          error={touched.state_id && Boolean(errors.state_id)}
                          helperText={
                            (touched.state_id && errors.state_id) ||
                            (!values.country_id
                              ? "Please select a country first"
                              : null)
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      disabled={
                        !values.country_id ||
                        !values.state_id ||
                        states.length === 0 ||
                        (!isEditMode && props.tempFormData?.assest_id)
                      }
                      id="district-autocomplete"
                      options={district}
                      getOptionLabel={(option) => option.district_name || null}
                      value={
                        district.find(
                          (dist) =>
                            dist.district_id === values.district_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "district_id",
                          newValue ? newValue.district_id : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              District{""}
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </span>
                          }
                          variant="outlined"
                          error={
                            touched.district_id && Boolean(errors.district_id)
                          }
                          helperText={touched.district_id && errors.district_id}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          City/Village{""}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="village"
                      value={values.village}
                      onChange={(e) => setFieldValue("village", e.target.value)}
                      error={touched.village && Boolean(errors.village)}
                      helperText={touched.village && errors.village}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Pin Code{""}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="pin"
                      value={values.pin}
                      onChange={(e) => setFieldValue("pin", e.target.value)}
                      error={touched.pin && Boolean(errors.pin)}
                      helperText={touched.pin && errors.pin}
                      disabled={!isEditMode && props.tempFormData?.assest_id}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} className="button-container" mt={3}>
                  {editOnExistingAssest && (
                    <Button onClick={onClickExistingCancel}>Cancel</Button>
                  )}
                  {props.tempFormData?.assest_id && !isEditMode && (
                    <>
                      <Button
                        variant="primary"
                        className="m-3"
                        onClick={() => setIsEditMode(true)}>
                        Edit
                      </Button>

                      <Button
                        variant="danger"
                        onClick={() =>
                          handleAssetDelete(props.tempFormData?.assest_id)
                        }
                        // disabled={!editForm}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  {isEditMode ? (
                    props.tempFormData?.assest_id ? (
                      <Button
                        variant="primary"
                        onClick={() =>
                          handleassestEdit(
                            props.tempFormData?.assest_id,
                            values
                          )
                        }>
                        Save
                      </Button>
                    ) : (
                      <Button variant="primary" type="submit">
                        Save and Next
                      </Button>
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="asset-details">
          {props.tempFormData?.assest_id && !isEditMode && (
            <Grid
              className="edit_btn"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => setIsEditMode(true)}>
              <Typography variant="body2" ml={1}>
                <EditIcon />
                Edit
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {isAsset ? "Asset Name" : "License Name"}
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.assest_name
                      ? props.tempFormData?.assest_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {isAsset ? "Address" : "Location"}
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.address}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          {!isAsset && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        Survey Number
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.survey_number}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        License status
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.status}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        License Start Date
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.start_date
                          ? formatDate(props.tempFormData?.start_date)
                          : "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        License End Date
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.end_date
                          ? formatDate(props.tempFormData?.end_date)
                          : "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        Operation Status
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.operation_status || "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {isAsset && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        Emergency Contact Person
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.emergency_contact_person
                          ? props.tempFormData?.emergency_contact_person
                          : "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        Emergency Contact Number
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.emergency_contact_phone
                          ? props.tempFormData?.emergency_contact_phone
                          : "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    Site Category
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.site_category_name
                      ? props.tempFormData?.site_category_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    Mine Material
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.material_name
                      ? props.tempFormData?.material_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {isAsset ? "Site Size" : "Extent(Ha)"}
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.site_size}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          {isAsset && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        Company Owned By
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.company_profile_name
                          ? props.tempFormData?.company_profile_name
                          : "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="left">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        Owner Ship Type
                      </span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item sm={1} className="hidden-xs">
                  <Typography variant="h6">
                    <Box display="flex" justifyContent="center">
                      <span>:</span>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSze: "12px",
                        }}>
                        {props.tempFormData?.owner_ship_type
                          ? props.tempFormData?.ownership_name
                          : "-"}
                      </span>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    Country
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.country_name
                      ? props.tempFormData?.country_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {" "}
                    State
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.state_name
                      ? props.tempFormData?.state_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    District
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.district_name
                      ? props.tempFormData?.district_name
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    Village
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.village
                      ? props.tempFormData?.village
                      : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6">
                <Box display="flex" justifyContent="left">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    Pin
                  </span>
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={1} className="hidden-xs">
              <Typography variant="h6">
                <Box display="flex" justifyContent="center">
                  <span>:</span>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6" sx={{ fontWeight: "normal" }}>
                <Box display="flex" justifyContent="flex-start">
                  <span
                    style={{ textTransform: "capitalize", fontSze: "12px" }}>
                    {props.tempFormData?.pin ? props.tempFormData?.pin : "-"}
                  </span>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

AssetForm.propTypes = {
  setFormVisible: PropTypes.func.isRequired,
  setSelectedLocation: PropTypes.func,
  isEditMode: PropTypes.bool,
  setIsEditMode: PropTypes.func,
  isAsset: PropTypes.bool,
  tempFormData: PropTypes.object,
  moveToNextTab: PropTypes.func,
  selectedLocationFromParent: PropTypes.object,
  setAssetId: PropTypes.func,
  setIsAsset: PropTypes.func,
};

export default AssetForm;
