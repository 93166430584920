import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, message, Popconfirm } from "antd";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import "./index.css";
import MUIDataTable from "mui-datatables";
import axios from "../../api";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkAssetModal from "./LinkAssetModal";
import PropTypes from "prop-types";

const ListView = ({ assetId }) => {
  const [assets, setAssets] = useState([]);
  const [actualAssets, setActualAssets] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchAssets = async () => {
    try {
      const linkresponse = await axios.get(`/assests/linked-assets/${assetId}`);
      const { data } = linkresponse;
      setAssets(data.list);
      const response = await axios.get("/assests", {
        params: {
          filters: {
            type: "A",
          },
        },
      });
      setActualAssets(response.data.list);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    assetId && fetchAssets();
  }, [assetId]);

  const handleEdit = (id) => {
    localStorage.setItem("assetId", id);
    localStorage.setItem("mode", "edit"); // Set mode to view
    window.location.reload();
  };

  const confirmDelete = async (id, linkLicenseId) => {
    try {
      await axios.delete(
        `/assests/linked-assets/${assetId}/${id}/${linkLicenseId}`
      );
      message.success("Assest removed successfully");
      fetchAssets();
    } catch (error) {
      console.error("Error details:", error);
      message.error(error.response.data.error);
    }
  };

  const handleView = (id) => {
    localStorage.setItem("assetId", id);
    localStorage.setItem("mode", "view"); // Set mode to view
    window.location.reload();
  };

  const options = {
    responsive: "standard",
    selectableRows: "none",
    selectableRowsHeader: false,
    print: false,
    download: false,
    rowsPerPageOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50], // Add 15 to the options
    rowsPerPage: 30,
    customToolbar: () => {
      return <CustomToolbar />;
    },
  };
  const CustomToolbar = () => {
    return (
      <button
        variant="contained"
        className="btn btn-success mb-2"
        style={{ background: "#330066" }}
        onClick={() => setShowModal(true)}>
        Link asset
      </button>
    );
  };

  const columns = [
    {
      name: "assest_name",
      label: "Site Name",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value, tableMeta) => {
          const assetid = tableMeta.rowData[6]; // Assuming asset_id is available in the same row
          return (
            <span
              style={{
                color: "#3f51b5",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleView(assetid);
              }}>
              {value}
            </span>
          );
        },
      },
    },

    {
      name: "address",
      label: "Address",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "site_category_name",
      label: "Category",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "material_name",
      label: "Mine Material",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "survey_number",
      label: "Survey Number",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "document_number",
      label: "Document Number",
      options: {
        sort: true,
        display: "true",
      },
    },

    {
      name: "assest_id",
      label: "Actions",
      options: {
        sort: false,
        display: "true",
        filter: false,
        viewColumns: false, // Hides from column selection menu
        customBodyRender: (value, tableMeta) => {
          const linkLicenseId = tableMeta.rowData[tableMeta.rowData.length - 1]; // Assuming it's the last field in dataset
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  padding: "6px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minWidth: "auto",
                  margin: "1px",
                }}
                onClick={() => handleEdit(value)}>
                <Tooltip title="Edit">
                  <EditIcon style={{ color: "#3f51b5" }} />
                </Tooltip>
              </Button>
              <Button
                style={{
                  padding: "6px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minWidth: "auto",
                  margin: "1px",
                }}
                onClick={() => handleView(value)}>
                <Tooltip title="View">
                  <VisibilityIcon style={{ color: "#330066" }} />
                </Tooltip>
              </Button>
              <Popconfirm
                title="Remove the asset"
                description="Are you sure to Remove this asset?"
                onConfirm={() => confirmDelete(value, linkLicenseId)}
                okText="Yes"
                cancelText="No">
                <Tooltip title="Remove asset">
                  <Button style={{ marginRight: "2px" }}>
                    <DeleteIcon color="error" />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          );
        },
      },
    },

    {
      name: "company_profile_name",
      label: "Company Owned By",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "ownership_name",
      label: "Owner Ship type",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "emergency_contact_person",
      label: "Contact Person Name",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "emergency_contact_phone",
      label: "Contact Person Number",
      options: {
        sort: true,
        display: "false",
      },
    },
    {
      name: "link_asset_id",
      options: {
        sort: false,
        display: false,
        filter: false,
        viewColumns: false, // Hides from column selection menu
      },
    },
  ];

  columns.forEach((column) => {
    if (column.label === "Actions") {
      return;
    }
    if (!column.options) {
      column.options = {};
    }
    column.options.setCellProps = () => ({
      style: { minWidth: "120px", maxWidth: "120px" },
    });
  });

  return (
    <>
      <Container fluid>
        <Box
          mt={3}
          sx={{ width: "100%", bgcolor: "background.paper", marginBottom: 10 }}>
          <MUIDataTable
            title="Assets List"
            data={assets}
            columns={columns}
            options={options}
          />
        </Box>
      </Container>
      {showModal && (
        <LinkAssetModal
          open={showModal}
          onClose={() => setShowModal(false)}
          users={actualAssets}
          license_id={assetId}
          fetchAssets={fetchAssets}
        />
      )}
    </>
  );
};

ListView.propTypes = {
  assetId: PropTypes.string, // Assuming license_id is a string
};
export default ListView;
