/* eslint-disable */
import React, { useState, useEffect } from "react";
import docTypeConstants from "../../constants/DocType.json";
import NotificatioPeriod from "../../constants/NotificationsPeriod.json";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import "./index.css";
import {
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import axios from "../../api";
import { message } from "antd";
import { useDropzone } from "react-dropzone";
import AWS from "aws-sdk";
import AppLoader from "../AppLoader";
import { isBefore, subMonths, parseISO } from "date-fns";
import PropTypes from "prop-types";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const acceptedFileTypes = ["pdf", "jpeg", "jpg", "png", "xls", "docx", "zip"];

const commonValidationSchema = Yup.object({
  document_type: Yup.string().required("Document type is required"),
  date_of_doc: Yup.date().required("Date of Document is required"),
  document_number: Yup.string()
    .trim()
    .matches(/^\S+$/, "Document Number cannot contain spaces")
    .required("Document Number is required"),
  survey_number: Yup.string()
    .trim() // This will remove leading and trailing spaces but allow spaces within the string
    .nullable()
    .optional(),
  sro: Yup.string().nullable().optional(),
  uploadedFiles: Yup.array()
    .min(1, "At least one file is required")
    .test(
      "fileType",
      "Only PDF, JPG, PNG, XLS, DOCX, and ZIP files are accepted",
      function (files) {
        if (!files) return true; // Skip validation if no files are present
        return files.every((file) => acceptedFileTypes.includes(file.fileType));
      }
    ),
});
const assetValidationSchema = Yup.object({
  size_covered: Yup.number()
    .typeError("Size Covered must be a valid number") // Ensures the input is a number
    .required("Size Covered is required"),
});
const licenseValidationSchema = Yup.object({
  status: Yup.string().required("License status is required"),
  document_renewal_date: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .test(
      "is-valid-or-empty",
      "Document renewal date must be a valid date",
      (value) => {
        // Allow null or undefined values
        return !value || value instanceof Date;
      }
    ),
  notification_days: Yup.number()
    .nullable()
    .test(
      "valid-notification",
      "Notification date must not be in the past",
      function (value) {
        const { document_renewal_date } = this.parent;
        if (document_renewal_date && value) {
          // Ensure document_renewal_date is a Date object
          const renewalDate =
            typeof document_renewal_date === "string"
              ? parseISO(document_renewal_date)
              : document_renewal_date;

          // Calculate the notification date
          const notificationDateLicence = subMonths(renewalDate, value);

          // Check if the calculated date is in the past
          return !isBefore(notificationDateLicence, new Date());
        }
        return true; // Skip validation if document_renewal_date is empty
      }
    ),
});
const leaseValidationSchema = Yup.object({
  document_type: Yup.string().required("Document type is required"),
  date_of_doc: Yup.date().required("Date of Document is required"),
  document_number: Yup.string().required("Document Number is required"),
  size_covered: Yup.number()
    .typeError("Size Covered must be a valid number") // Ensures the input is a number
    .required("Size Covered is required"), // Marks it as a required field

  // document_renewal_date: Yup.date().required("Document Renewal Date is required"),
  sro: Yup.string().nullable().optional(),
  lease_ref_no: Yup.string().required("Lease Ref No. is required"), // Make lease_ref_no required
});

const DocumentForm = (props) => {
  const { assetId, fetchDocuments, tempFormData, isAsset, setAssetId } = props;
  const defaultObj = {
    document_type: null,
    date_of_doc: null,
    document_number: null,
    survey_number: "",
    size_covered: null,
    document_renewal_date: null,
    sro: "",
    lease_ref_no: null,
    assest_id: props.assetId,
    status: null,
    notification_days: null,
    notification_date: null,
  };
  const user = useSelector((state) => state.auth.user);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const notificatioPeriod = NotificatioPeriod.NotificatioPeriod;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [initialDocType, setInitialDocType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showLeaseRefField, setShowLeaseRefField] = useState(false);
  const formData = {
    assest_id: assetId,
    ...defaultObj,
  };
  const [isLeaseRefSelected, setIsLeaseRefSelected] = useState(false);
  useEffect(() => {
    setDocTypeOptions(
      isAsset ? docTypeConstants.DocType : docTypeConstants.Licensedoc
    );
    return () => {
      // Clean up object URLs
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [uploadedFiles]);
  const notificationDate = (document_renewal_date, value) => {
    const renewalDate =
      typeof document_renewal_date === "string"
        ? parseISO(document_renewal_date)
        : document_renewal_date;

    // Calculate the notification date
    const notificationDateNew = subMonths(renewalDate, value);
    return convert(notificationDateNew);
  };
  const convert = (dateVal) => {
    let date = new Date(dateVal),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpg"],
      "image/png": [".png"],
      "application/zip": [".zip"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        message.error(
          "Only PDF,  JPG, PNG, XLS, DOCX, and ZIP files are accepted."
        );
      }
      handleFileProcessing(acceptedFiles);
    },
  });

  const handleFileProcessing = (files) => {
    setUploadedFiles((prevFiles) => [
      ...prevFiles,
      ...files.map((file) => {
        const fileType = file.name.split(".").pop();
        return Object.assign(file, {
          preview: fileType.startsWith("image/")
            ? URL.createObjectURL(file)
            : null,
          fileType,
        });
      }),
    ]);
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleFileProcessing(files);
  };
  const validationSchema = () => {
    if (isAsset) {
      return commonValidationSchema.concat(assetValidationSchema);
    } else {
      return commonValidationSchema.concat(licenseValidationSchema);
    }
  };

  return (
    <div className="map-form-fields">
      <Formik
        initialValues={formData}
        // validationSchema={validationSchema}
        validationSchema={
          isLeaseRefSelected ? leaseValidationSchema : validationSchema
        }
        // enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          values.document_expiry_in_days = 90;
          values.assest_id = tempFormData.assest_id || props.assetId;
          values.created_by = user?.user_id;
          values.updated_by = user?.user_id;
          values.notification_date =
            values.document_renewal_date && values.notification_days
              ? notificationDate(
                  values.document_renewal_date,
                  values.notification_days
                )
              : null;

          // Convert empty strings to null
          if (values.document_renewal_date === "") {
            values.document_renewal_date = null;
          }

          try {
            setLoading(true);

            // Check if a file has been uploaded
            if (!uploadedFiles || uploadedFiles.length === 0) {
              setLoading(false);
              message.error("File is required!");
              return; // Stop the form submission if no file is uploaded
            }

            const file = uploadedFiles[0];
            const fileName = `${Date.now()}-${file.name}`;
            const params = {
              // Bucket: "dev-asset-mgmt",
              Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
              Key: fileName,
              Body: file,
              ContentType: file.type,
            };
            if (values.assest_id) {
              s3.upload(params, async (err, data) => {
                if (err) {
                  console.error("Error uploading file:", err);
                  message.error("Error uploading file");
                } else {
                  try {
                    const response = await axios.post(
                      "/documents/add-document",
                      values
                    );

                    const docFileData = {
                      document_id: response.data.id[0],
                      file_data: fileName,
                      file_type: file.fileType,
                      file_name: fileName,
                      created_by: user.user_id,
                      updated_by: user.user_id,
                      share_expiry_date: values.document_renewal_date || null,
                      is_active: 1,
                    };
                    await axios.post(
                      "/documents-data/add-document-file",
                      docFileData
                    );

                    resetForm();
                    setInitialDocType(null); // Clear document type selection
                    setUploadedFiles([]); // Clear uploaded files
                    setLoading(false);
                    setAssetId(tempFormData.assest_id || assetId);
                    message.success(response.data.message);

                    fetchDocuments();
                    props.moveToNextTab(tempFormData.assest_id || assetId);
                  } catch (error) {
                    setLoading(false);
                    message.error(error?.response?.data?.error);
                  }
                }
              });
            } else {
              const Tname = isAsset ? "Assest" : "License";
              message.error(`${Tname} not found. Please add ${Tname} first`);
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
            message.error("Error submitting form");
          }
        }}>
        {({ setFieldValue, values, touched, errors, resetForm }) => (
          <Form className="row form-style">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={6}>
                <Autocomplete
                  id="docType"
                  options={docTypeOptions}
                  getOptionLabel={(option) => option.Name || null}
                  value={initialDocType}
                  onChange={(event, newValue) => {
                    setInitialDocType(newValue);
                    setIsLeaseRefSelected(newValue?.Name === "Lease deed");
                    setFieldValue(
                      "document_type",
                      newValue ? newValue.Name : null
                    );
                    setShowLeaseRefField(newValue?.Name === "Lease deed");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Doc Type{""}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      variant="outlined"
                      error={
                        touched.document_type && Boolean(errors.document_type)
                      }
                      helperText={touched.document_type && errors.document_type}
                    />
                  )}
                />
              </Grid>
              {showLeaseRefField && (
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    label={
                      <span>
                        Lease Ref No.{""}
                        <span style={{ color: "red", marginLeft: "4px" }}>
                          *
                        </span>
                      </span>
                    }
                    name="lease_ref_no"
                    value={values.lease_ref_no}
                    onChange={(e) =>
                      setFieldValue("lease_ref_no", e.target.value)
                    }
                    error={touched.lease_ref_no && Boolean(errors.lease_ref_no)}
                    helperText={touched.lease_ref_no && errors.lease_ref_no}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  fullWidth
                  label={
                    <span>
                      Document Number{""}
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  name="document_number"
                  value={values.document_number}
                  onChange={(e) =>
                    setFieldValue("document_number", e.target.value)
                  }
                  error={
                    touched.document_number && Boolean(errors.document_number)
                  }
                  helperText={touched.document_number && errors.document_number}
                />
              </Grid>
              {isAsset && (
                <>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Survey Number"
                      name="survey_number"
                      value={values.survey_number}
                      onChange={(e) =>
                        setFieldValue("survey_number", e.target.value)
                      }
                      error={
                        touched.survey_number && Boolean(errors.survey_number)
                      }
                      helperText={touched.survey_number && errors.survey_number}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Size Covered{""}
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            *
                          </span>
                        </span>
                      }
                      name="size_covered"
                      value={values.size_covered}
                      onChange={
                        (e) =>
                          setFieldValue(
                            "size_covered",
                            parseFloat(e.target.value) || null
                          ) // Convert to float and handle empty input
                      }
                      type="number" // Ensures numeric input
                      inputProps={{ step: "any" }} // Allows decimal values
                      error={
                        touched.size_covered && Boolean(errors.size_covered)
                      }
                      helperText={touched.size_covered && errors.size_covered}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6} sm={6}>
                <Field
                  label={
                    <span>
                      Date of Document{""}
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </span>
                  }
                  name="date_of_doc"
                  as={TextField}
                  type="date"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={errors.date_of_doc && touched.date_of_doc}
                  helperText={touched.date_of_doc && errors.date_of_doc}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Field
                  name="document_renewal_date"
                  label="Document Renewal Date"
                  as={TextField}
                  type="date"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={
                    errors.document_renewal_date &&
                    touched.document_renewal_date
                  }
                  helperText={
                    touched.document_renewal_date &&
                    errors.document_renewal_date
                  }
                />
              </Grid>
              {isAsset && (
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    fullWidth
                    label="SRO"
                    name="sro"
                    value={values.sro}
                    onChange={(e) => setFieldValue("sro", e.target.value)}
                    error={touched.sro && Boolean(errors.sro)}
                    helperText={touched.sro && errors.sro}
                  />
                </Grid>
              )}
              {!isAsset && (
                <>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        select
                        name="status"
                        value={values.status}
                        label={
                          <span>
                            License status{""}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              *
                            </span>
                          </span>
                        }
                        error={touched.status && Boolean(errors.status)}
                        helperText={touched.status && errors.status}
                        onChange={(event) => {
                          setFieldValue("status", event.target.value); // Update the form field value
                        }}>
                        <MenuItem value={"Granted"}>Granted</MenuItem>
                        <MenuItem value={"Applied for"}>Applied for</MenuItem>
                        <MenuItem value={"Not applied"}>Not applied</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  {values.document_renewal_date && (
                    <Grid item xs={12} md={6} sm={6}>
                      <Autocomplete
                        id="notification_days"
                        options={notificatioPeriod}
                        getOptionLabel={(option) => option.Name || null}
                        value={
                          notificatioPeriod.find(
                            (option) => option.Id === values.notification_days
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "notification_days",
                            newValue ? newValue.Id : null
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Notification Month"
                            variant="outlined"
                            error={
                              touched.notification_days &&
                              Boolean(errors.notification_days)
                            }
                            helperText={
                              touched.notification_days &&
                              errors.notification_days
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <Typography sx={{ marginTop: 3 }}>
              Note<span style={{ color: "red", marginLeft: "4px" }}>*</span>:
              Only PDF, JPG, PNG, XLS, DOCX, and ZIP files are accepted.
            </Typography>
            <Box
              {...getRootProps({ className: "dropzone" })}
              sx={{
                border: "2px dashed #cccccc",
                borderRadius: "5px",
                padding: "20px",
                marginTop: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}>
              <input {...getInputProps()} onChange={handleFileSelect} />
              <Typography>
                Drag 'n' drop some files here, or click to select files
              </Typography>
              <Box sx={{ marginTop: "20px" }}>
                {uploadedFiles.map((file) => (
                  <Box
                    key={file.name}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}>
                    {file.preview ? (
                      <img
                        src={file.preview}
                        alt="Preview"
                        style={{
                          width: "100px",
                          height: "auto",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <Typography>{file.name}</Typography>
                    )}
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() =>
                        setUploadedFiles((prev) =>
                          prev.filter((f) => f.name !== file.name)
                        )
                      }>
                      Remove
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
            <div className="center">
              <Button
                variant="primary"
                className="button m-3"
                type="submit"
                style={{ marginTop: 50 }}>
                Save & Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {loading && <AppLoader />}
    </div>
  );
};
DocumentForm.propTypes = {
  assetId: PropTypes.string, // Assuming assetId is a string
  fetchDocuments: PropTypes.func, // Assuming fetchDocuments is a function
  tempFormData: PropTypes.object, // Assuming tempFormData is an object
  isAsset: PropTypes.bool,  // Assuming isAsset is a boolean
  setAssetId: PropTypes.func, // Assuming setAssetId is a function
  moveToNextTab: PropTypes.func
};
export default DocumentForm;
